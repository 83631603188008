export default {
  "common": {
    "Beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Húfa"])},
    "Mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vettlingar"])},
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sokkar"])},
    "Sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peysa"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mál"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu stærðarflokk"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stærðarflokkur"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barn"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullorðinn"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sérsníða"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mánaða"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ára"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reikna uppskrift"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfesta úr prufu"])},
    "Advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ítarlegar stillingar"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegund stroffs"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfalt (1 sl + 1 br)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvöfalt (2 sl + 2 br)"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drjúg er lykkjustundin"])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útreikningar sem fylgja prjónaskap geta verið tímafrekir og flóknir. Með Knittable er hægt að spara sér tíma í undirbúning og nýta hann frekar í prjónaskapinn."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stutt af:"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfesta"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykkjufjöldi"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umferðafjöldi"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfesta segir til um hversu margar lykkjur og umferðir eru í prjónuðum fleti sem er 10 cm á kant."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Á flestu garni er gefin upp prjónfesta miðað við ákveðna prjónastærð. Ef þú prjónar meðalfast er þér óhætt að nota hana."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ef þú prjónar laust eða fast geturðu notað uppgefnu prjónfestuna með því að nota minni eða stærri prjóna en lagt er til af garnframleiðanda, en ávalt er öruggast að gera prjónfestuprufu."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja á 10 cm"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.d. 18 lykkjur fyrir Léttlopa"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða á 10 cm"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T.d. 24 umferðir fyrir Léttlopa"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áætla umferðafjölda út frá lykkjufjölda"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skóstærð"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu skóstærð"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál ökkla"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál sokks yfir ökkla (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn ummál ökkla"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál fótar"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál sokks yfir táberg (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn ummál sokks"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæð sokks"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæð sokks frá hæl upp á fótlegg (cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn hæð sokks"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd sokks"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd sokks frá hæl til táar (cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lengd sokks"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggur"])},
    "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fitjið upp ", _interpolate(_named("castOnStitchCount")), " lykkjur á fjóra prjóna af þeirri stærð sem gefur rétta tilgreinda prjónfestu (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur). Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til stykkið er ", _interpolate(_named("cuffLength")), " cm langt."])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("anklePieceLength")), " cm slétt."])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæll"])},
    "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú er prjónuð hæltunga. Prjónið slétt af 1. prjóni, snúið við, takið fyrstu lykkjuna óprjónaða og prjónið brugðið til baka. Sameinið um leið lykkjurnar af 1. og 4. prjóni (", _interpolate(_named("castOnStitchCount_half")), " lykkjur) sem munu mynda hæltunguna. Prjónið slétt yfir þessar lykkjur (slétt frá réttu, brugðið frá röngu og fyrsta lykkja ávalt tekin óprjónuð) þar til hæltungan telur ", _interpolate(_named("heelFlapRowCount")), " umferðir ef talið er þeim megin sem áður var 4. prjónn. Næsta umferð er á réttunni."])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Því næst tekur við hælúrtakan:"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. umferð hælúrtöku:"])},
    "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið eina lykkju óprjónaða, prjónið svo slétt þar til ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " lykkur eru eftir af hæltungunni. Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var áður en snúið er við."])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. umferð:"])},
    "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið eina lykkju óprjónaða, prjónið brugðið (slétt á réttunni) þar til ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " lykkur eru eftir. Prjónið 2 brugðnar lykkjur saman áður en snúið er við."])},
    "Heel_paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið umferðir 1 og 2, nema þannig að ", _interpolate(_named("heelFlapMarginStitchCount")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusOne")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusTwo")), " o.s.frv. lykkjur séu eftir, þangað til allar hliðarlykkjur hafa verið felldar inn í hælinn."])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fótur"])},
    "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú er aftur prjónað í hring. Skiptið lykkjunum í hæltungunni niður á tvo prjóna og takið upp allar ", _interpolate(_named("heelFlapEdgeStitchCount")), " jaðarlykkjurnar í hvorri hlið hæltungunnar og bætið á sinnhvorn prjóninn. Takið auk þess upp 1 lykkju við upphaf hæltungunnar hvoru megin (úr síðustu umferð fyrir hæltungu) og bætið á prjónana. Byrjun umferðarinnar er núna á milli þessara tveggja prjóna."])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið fyrstu 2 umferðirnar í hring á eftirfarandi máta:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið slétt. Til þess að minnka líkur á að göt myndist þegar nýju lykkjurnar í hliðum hæltungunnar eru prjónaðar er gott að prjóna þær lykkjur snúnar."])},
    "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið slétt af 1. prjóni þangað til 3 lykkjur eru eftir á prjóninum, prjónið tvær lykkjur saman og því næst síðustu lykkjuna. Prjónið slétt af 2. og 3. prjóni, prjónið 1 l slétt af 4. prjóni, takið því næst 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var.  Prjónið svo slétt restina af prjóninum."])},
    "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið umferðir 2 og 3 þangað til lykkjurnar eru orðnar ", _interpolate(_named("footStitchCount")), "."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt þar til sokkurinn mælist ", _interpolate(_named("footLengthMinusTipLength")), " cm frá hæl."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tá"])},
    "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gætið að því áður en táúrtaka hefst að jafn margar lykkjur séu á prjónum 1 og 4 samtals (il), og prjónum 2 og 3 samtals (rist)."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtakan fer fram með eftirfarandi hætti:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. umferð (úrtökuumferð):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Prjónið slétt þar til ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " lykkjur eru eftir.  Prjónið 2 sléttar saman", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", og svo ", _interpolate(_named("tipMarginStitchCount")), " sl"])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), "Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt það sem eftir er prjóni."])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið 1 l sl. "])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Prjónið eins og prjón 1."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Prjónið eins og prjón 2."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið úrtökuumferðir héðan í frá, þangað til ", _interpolate(_named("finalRowStitchCount")), " lykkjur eru eftir samtals á öllum prjónum. Slítið bandið frá og dragið í gegn um lykkjurnar. Felið enda."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið hinn sokkinn eins."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fitjið upp ", _interpolate(_named("castOnStitchCount")), " lykkjur á fjóra prjóna (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur) eða á hringprjón. Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til stykkið er ", _interpolate(_named("cuffLength")), " cm langt."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("heelflapStitchCountLeft")), " lykkjur af 1. prjóni slétt, snúið við. Takið fyrstu lykkjuna óprjónaða, prjónið hinar ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " lykkjurnar af 1. prjóni brugðið. Setjið prjónamerki og prjónið ", _interpolate(_named("heelflapStitchCountRight")), " lykkjur af 4. prjóni brugðið. Snúið aftur við. Þessar ", _interpolate(_named("heelflapStitchCount")), " lykkjur munu mynda hæltunguna og hinar ", _interpolate(_named("instepStitchCount")), " ristina."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið nú fram og til baka yfir lykkjurnar ", _interpolate(_named("heelflapStitchCount")), ", slétt frá réttu og brugðið frá röngu, og takið fyrstu lykkjuna ávalt óprjónaða. Haldið áfram þar til hæltungan telur ", _interpolate(_named("heelFlapRowCount")), " umferðir ef talið er hægra megin við prjónamerki, eða þar til jaðarlykkjurnar á hlið hæltungunnar eru orðnar ", _interpolate(_named("heelFlapEdgeStitchCount")), " báðu megin. Næsta umferð er á réttunni."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið eina lykkju óprjónaða, prjónið slétt að prjónamerki. Prjónið ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " lykkju(r) slétt. Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var áður en snúið er við."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið eina lykkju óprjónaða, prjónið brugðið að prjónamerki. Prjónið ", _interpolate(_named("heelFlapCenterRightMinusOne")), " lykkju(r) brugðið. Prjónið 2 brugðnar lykkjur saman áður en snúið er við."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endurtakið umferðir 1 og 2 þangað til allar hliðarlykkjur hafa verið felldar inn í hælinn."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið eina lykkju óprjónaða, prjónið ", _interpolate(_named("heelFlapCenterRightMinusOne")), " lykkju(r) slétt að prjónamerki."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú er aftur prjónað í hring. Skiptið lykkjunum í hæltungunni aftur niður á tvo prjóna við prjónamerki og takið upp allar ", _interpolate(_named("heelFlapEdgeStitchCount")), " jaðarlykkjurnar í hvorri hlið hæltungunnar og bætið á sinnhvorn prjóninn. Takið auk þess upp 1 lykkju við upphaf hæltungunnar hvoru megin (úr síðustu umferð fyrir hæltungu) og bætið á prjónana. Byrjun umferðarinnar er áfram á milli þessara tveggja prjóna."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú ættu að vera ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " lykkjur á prjónunum (", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " og ", _interpolate(_named("fourthNeedleStitchCount")), " lykkjur). Ef hringprjónn er notaður, er gott að setja prjónamerki þar sem samskeyti 1. og 2. prjóns væru og samskeyti 3. og 4. prjóns."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið slétt af 1. prjóni þangað til 2 lykkjur eru eftir á prjóninum, prjónið 2 lykkjur saman. Prjónið slétt af 2. og 3. prjóni. Takið 1 l óprjónaða af 4. prjóni, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt út umferðina."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið 2. og 3. umferð ", _interpolate(_named("heelDecreaseCountMinusOne")), " sinnum í viðbót þangað til lykkjurnar eru orðnar ", _interpolate(_named("footStitchCount")), "."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gætið að því áður en táúrtaka hefst að lykkjunum sé jafndreift á prjónana, eða ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur. Eins ef hringprjónn er notaður, er gott að setja prjónamerki aftur þar sem samskeyti 1. og 2. prjóns væru og samskeyti 3. og 4. prjóns."])}
    }
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. umferð:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. umferð:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. og 3. umferð:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. - 4. umferð:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. umferð:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. umferð:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. umferð:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. og 7. umferð:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. umferð:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. umferð:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónn 1:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónn 2:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónn 3:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónn 4:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið slétt."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið úrtökuumferð."])}
  },
  "mittens": {
    "Cuff_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál stroffs"])},
    "Cuff_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál stroffs mælt yfir úlnlið (cm)"])},
    "Type_in_cuff_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn ummál stroffs"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd stroffs"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd stroffs (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lengd stroffs"])},
    "Hand_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál handar"])},
    "Hand_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál handar yfir handarbak (cm)"])},
    "Type_in_hand_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn ummál handar"])},
    "Hand_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd handar"])},
    "Hand_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd frá úlnlið fram á fingurgóma (cm)"])},
    "Type_in_hand_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lengd handar"])},
    "Felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þæfing"])},
    "Felting_information_bubble_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Til að þæfa vettlinga eru þeir prjónaðir stærri en þeir eiga að vera og svo þæfðir í höndum eða í þvottavél þar til þeir hafa minnkað í rétta stærð."])},
    "Felting_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Við þessa meðferð verða vettlingarnir þéttari og halda þar af leiðandi höndum hlýrri og þurrari en ella. Ending vettlingana eykst að sama skapi."])},
    "Felting_density_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þéttleiki þæfingar"])},
    "Choose_how_much_to_felt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu hversu mikið á að þæfa"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engin þæfing"])},
    "Felt_a_little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lítill (prjónaðir 10% stærri)"])},
    "Felt_somewhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meðal (prjónaðir 20% stærri)"])},
    "Felt_a_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mikill (prjónaðir 30% stærri)"])},
    "Yarn_for_felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garn fyrir þæfingu"])},
    "Yarn_for_felting_info_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allt ullargarn sem hefur ekki verið superwash-meðhöndlað hentar vel til þæfingar. Mismunandi garntegundir þæfast þó á mismunandi máta, og fer útkoman einnig eftir prjónfestunni, því laust prjón þæfist meira en þétt prjón."])},
    "Yarn_for_felting_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmöguleikarnir \"Lítið\", \"Meðal\" og \"Mikið\" eru því einungis ráðgefandi og erfitt að segja nákvæmlega til um útkomuna fyrir allar garntegundir."])},
    "Suggestion_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillaga:"])},
    "Yarn_for_felting_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvöfaldur Plötulopi prjónaður með prjónfestu 16 l/10 cm."])},
    "Cuff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroff"])},
    "Cuff_felting_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroffprjón þæfist meira á lengdina en slétt prjón, svo lengd stroffsins hefur verið aukin hlutfallslega meira en hin málin í uppskriftinni."])},
    "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fitjið upp ", _interpolate(_named("castOnStitchCount")), " lykkjur á fjóra prjóna. Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til stykkið er ", _interpolate(_named("calculatedCuffLength")), " cm langt."])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgur"])},
    "Knit_a_round_and_increase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétta umferð og aukið út um ", _interpolate(_named("handCuffStitchCountDiff")), " lykkjur jafnt og þétt yfir umferðina, svo þær verða í heildina ", _interpolate(_named("handStitchCount")), "."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt þar til belgurinn er orðið ", _interpolate(_named("beforeThumbLength")), " cm langur, mælt frá stroffi."])},
    "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gætið þess að helmingur lykkjanna sé á 1. og 2. prjóni og hinn helmingurinn á 3. og 4. prjóni áður en haldið er áfram."])},
    "Right_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hægri vettlingur:"])},
    "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt af 1. og 2. prjóni og ", _interpolate(_named("thumbMarginStitchCount")), " lykkju(r) slétt af 3. prjóni. Takið aukaband og prjónið ", _interpolate(_named("thumbOpeningStitchCount")), " lykkjur slétt með því. Setið lykkjurnar með aukabandinu aftur yfir á vinstri prjón og prjónið þær og restina af umferðinni slétt með grunngarninu."])},
    "Left_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinstri vettlingur:"])},
    "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt af 1. prjóni og þar til ", _interpolate(_named("thumbMarginPlusThumbOpeningStitchCount")), " lykkjur eru eftir af 2. prjóni. Takið aukaband og prjónið ", _interpolate(_named("thumbOpeningStitchCount")), " lykkjur slétt með því. Setið lykkjurnar með aukabandinu aftur yfir á vinstri prjón og prjónið þær og restina af umferðinni slétt með grunngarninu."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtaka"])},
    "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér er aftur mikilvægt að athuga að helmingur lykkjanna sé á 1. og 2. prjóni og hinn helmingurinn á 3. og 4. prjóni."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtakan fer fram með eftirfarandi hætti:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. umferð (úrtökuumferð):"])},
    "Knit_one_stitch_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið 1 l sl."])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt það sem eftir er prjóni."])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " Prjónið slétt þar til ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " lykkjur eru eftir.  Prjónið 2 sléttar saman", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", og svo ", _interpolate(_named("tipMarginStitchCount")), " sl"])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Prjónið eins og prjón 1."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Prjónið eins og prjón 2."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið úrtökuumferðir í hverri umferð héðan í frá, þangað til ", _interpolate(_named("finalRowStitchCount")), " lykkjur eru eftir samtals á öllum prjónum. Slítið bandið frá og dragið í gegn um lykkjurnar."])},
    "Thumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þumall"])},
    "Thumb_description_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Til að prjóna þumalinn er aukabandið rakið upp og við það myndast ", _interpolate(_named("thumbOpeningStitchCountMinusOne")), " lykkjur í ofanverðu þumalgatinu og ", _interpolate(_named("thumbOpeningStitchCount")), " í því neðanverðu. Takið lykkjurnar upp ásamt 2 lykkjum úr hvorum jaðri gatsins. Þetta gera samtals ", _interpolate(_named("initialThumbStitchCount")), " lykkjur, og eru þar af ", _interpolate(_named("initialThumbStitchCountHalfFloor")), " fyrir efri helming þumalsins og ", _interpolate(_named("initialThumbStitchCountHalfCeil")), " fyrir þann neðri. Skiptið lykkjunum þannig að hvorum helmingi þumalsins sé skipt á tvo prjóna. Ef notaður er dragprjónn (e. magic loop) skal setja hvorn helming á sinnhvorn hluta dragprjónsins."])},
    "Thumb_first_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byrjið umferðina í þeim enda þumalgatsins sem er innar í lófanum. Prjónið jaðarlykkjurnar snúnar til að minnka líkur á götum."])},
    "Thumb_second_round_description_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ath. umferðin byrjar á efri helming þumalsins á hægri vettlingi, en þeim neðri á vinstri."])},
    "Thumb_second_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fækkið þumallykkjunum um ", _interpolate(_named("stitchesKnitTogetherInSecondRow")), " lykkjur með því að prjóna neðri helming þumalsins á eftirfarandi hátt. Prjónið fyrstu 2 lykkjurnar saman. Prjónið því næst slétt þar til 2 lykkjur eru eftir af neðri helming þumalsins, takið þá 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið efri helming þumalsins án úrtaka.  Eftir þessa umferð eru þumallykkjurnar orðnar ", _interpolate(_named("thumbStitchCount")), "."])},
    "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið svo slétt þar til þumallinn mælist ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
    "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið nú úrtökuumferðir eins og líst er hér fyrir neðan þangað til ", _interpolate(_named("thumbFinalRowStitchCount")), " lykkjur eru eftir samtals á öllum prjónum."])},
    "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerið eins fyrir hvorn helming þumals. Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt þar til 2 lykkjur eru eftir. Prjónið þær sléttar saman."])},
    "Thumb_break_the_yarn_and_pull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slítið bandið frá og dragið í gegn um lykkjurnar."])},
    "Weave_in_all_ends_and_knit_the_other_mitten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felið enda. Prjónið hinn vettlinginn eins, nema fylgið nú leiðbeiningum merktar „Vinstri vettlingur“ ef sá hægri var prjónaður síðast."])},
    "Felting_description_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hægt er að þæfa vettlingana bæði í höndunum og í þvottavél. Handþvotturinn veitir hvað nákvæmasta stjórn á stærð og formi, en þæfing getur verið mismikil efir þvottavélum og þvottaprógrömmum. Það kemur þó ekki að sök því ef vettlingarnir koma of litlir út má teygja þá upp í rétta stærð á meðan þeir eru ennþá rakir, og ef þeir eru ennþá of stórir má setja þá aftur á sama prógram og jafnvel hækka hitann."])},
    "Felting_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almennt gildir um þæfingu að hún eykst með hærra hitastigi, ef sápa er notuð, og ef aðrar flíkur eru þvegnar með vettlingunum. Þá þarf þó að gæta þess að hinar flíkurnar gefi ekki af sér ló."])},
    "Felting_a_little_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setjið vettlingana í þvottavél á 40° og stutt prógram (ekki ullarprógram) með vindingu. Notið dálítið af þvottaefni með."])},
    "Felting_somewhat_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setjið vettlingana í þvottavél á 40° og heilt venjulegt prógram (ekki ullarprógram) með vindingu. Notið dálítið af þvottaefni með."])},
    "Felting_a_lot_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setjið vettlingana í þvottavél á 60° og heilt venjulegt prógram (ekki ullarprógram) með vindingu. Notið dálítið af þvottaefni með."])},
    "v1_1_0": {
      "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fitjið upp ", _interpolate(_named("castOnStitchCount")), " lykkjur á fjóra prjóna (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur) eða á hringprjón. Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til stykkið er ", _interpolate(_named("calculatedCuffLength")), " cm langt."])},
      "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gætið að því áður en haldið er áfram að lykkjunum sé jafndreift á prjónana, eða ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur. Eins ef hringprjónn er notaður, er gott að setja prjónamerki þar sem samskeyti 2. og 3. prjóns væru (eftir ", _interpolate(_named("halfHandStitchCount")), " lykkjur)."])},
      "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("halfHandStitchCount")), " lykkjur slétt af 1. og 2. prjóni, eða að prjónamerki, og ", _interpolate(_named("thumbMarginStitchCount")), " lykkju(r) slétt af 3. prjóni. Takið aukaband og prjónið ", _interpolate(_named("thumbOpeningStitchCount")), " lykkjur slétt með því. Setið lykkjurnar með aukabandinu aftur yfir á vinstri prjón og prjónið þær og restina af umferðinni slétt með grunngarninu."])},
      "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("stitchCountUntilThumb")), " lykkjur slétt. Takið aukaband og prjónið ", _interpolate(_named("thumbOpeningStitchCount")), " lykkjur slétt með því. Setið lykkjurnar með aukabandinu aftur yfir á vinstri prjón og prjónið þær og síðustu ", _interpolate(_named("thumbMarginStitchCount")), " lykkjurnar af 2. prjóni, eða að prjónamerki, slétt með grunngarninu. Prjónið slétt restina af umferðinni"])},
      "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hér er aftur mikilvægt að athuga að lykkjunum sé jafndreift á prjónana, eða ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " lykkjur. Eins ef hringprjónn er notaður, er gott að gæta þess að prjónamerkið sé þar sem samskeyti 2. og 3. prjóns væru (eftir ", _interpolate(_named("halfHandStitchCount")), " lykkjur)."])},
      "Thumb_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Byrjið í þeim enda þumalgatsins sem er innar í lófanum og prjónið ", _interpolate(_named("thumbInitialHalfStitchCount")), " lykkjur fyrri helmings slétt, á meðan þið gætið þess að prjóna jaðarlykkjurnar snúnar til að minnka líkur á götum. Prjónið seinni helminginn á sama máta."])},
      "Thumb_second_round_right_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " lykkjur fyrri helmings slétt. Prjónið fyrstu 2 lykkjur seinni helmings saman. Prjónið ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " lykkjur slétt þar til 2 lykkjur eru eftir af seinni helmingi, takið þá 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var."])},
      "Thumb_second_round_left_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið fyrstu 2 lykkjurnar saman. Prjónið ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " lykkjur slétt þar til 2 lykkjur eru eftir af fyrri helmingi, takið þá 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " lykkjur seinni helmings slétt."])},
      "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú eru þumallykkjurnar orðnar ", _interpolate(_named("thumbStitchCount")), ". Prjónið slétt þar til þumallinn mælist ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
      "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið nú úrtökuumferðir eins og líst er hér fyrir neðan í hverri umferð þangað til ", _interpolate(_named("thumbFinalRowStitchCount")), " lykkjur eru eftir samtals á öllum prjónum."])},
      "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt þar til 2 lykkjur eru eftir af fyrri helmingi. Prjónið þær sléttar saman. Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið slétt þar til 2 lykkjur eru eftir af seinni helmingi. Prjónið þær sléttar saman."])}
    }
  },
  "common3": {
    "Size_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stærð"])},
    "Choose_a_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu stærð"])},
    "Print_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenta uppskrift"])},
    "This_browser_cannot_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Því miður býður þessi vafri ekki upp á að hægt sé að prenta uppskriftina. Opnið síðuna t.d. í Chrome til að prenta."])},
    "Decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtökuumferð:"])}
  },
  "beanie": {
    "Head_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál höfuðs"])},
    "Head_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ummál höfuðs þar sem það er breiðast yfir hnakka og enni (cm)"])},
    "Type_in_head_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn ummál höfuðs"])},
    "Brim_height_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd uppábrots"])},
    "Brim_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd kants sem er brotinn upp á húfunni (cm)"])},
    "Type_in_brim_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lengd uppábrots"])},
    "There_are_too_few_cast_on_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Það eru of fáar uppfitjunarlykkjur í þessari útfærslu. Vinsamlegast hækkið prjónfestuna eða veljið stærri stærð af húfu."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hólkur"])},
    "Ribbing_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroffi hluti"])},
    "Whole_beanie_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Húfan öll"])},
    "Only_brim_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bara uppábrotið"])},
    "Decrease_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtaka"])},
    "Even_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ósýnileg úrtaka"])},
    "Cross_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krossúrtaka"])},
    "Body_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fitjið upp ", _interpolate(_named("castOnStitchCount")), " lykkjur á passandi hringprjón."])},
    "Body_cast_on_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér má gjarnan nota ósýnilega fit (e. tubular cast on), en venjuleg silfurfit (e. long tail cast on) eða hvaða fitjar sem teygjast sæmilega virka þó vel líka."])},
    "body_description_ribbed_start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til hólkurinn mælist ", _interpolate(_named("straightCuffLength")), " cm."])},
    "body_description_stockinette_top_zero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt í ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm."])},
    "body_description_stockinette_top_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið slétt í ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm þar til hólkurinn er orðinn ", _interpolate(_named("lengthBeforeDecrease")), " cm langur frá uppfiti, eða ", _interpolate(_named("lengthBeforeDecreaseMinusFoldLength")), " cm ef hann er mældur með uppábroti upp á ", _interpolate(_named("foldLength")), " cm. Síðari mælingin er nákvæmari, sér í lagi ef prjónað er úr þykku garni, því hluti af lengdinni fer í brotið sjálft."])},
    "body_description_whole_ribbed_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tengið í hring og prjónið stroff, ", _interpolate(_named("cuffPatternRepCountHalf")), " sl og ", _interpolate(_named("cuffPatternRepCountHalf")), " br lykkjur á víxl, þangað til hólkurinn mælist ", _interpolate(_named("straightCuffLength")), " cm, eða ", _interpolate(_named("straightCuffLengthMinusFoldLength")), " cm ef hann er mældur með uppábroti upp á ", _interpolate(_named("foldLength")), " cm. Síðari mælingin er nákvæmari, sér í lagi ef prjónað er úr þykku garni, því hluti af lengdinni fer í brotið sjálft."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtaka"])},
    "First_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. úrtökuumferð:"])},
    "First_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l slétt og svo 2 l saman brugðið.**"])},
    "First_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið 1 sl og 1 br.**"])},
    "First_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l slétt og svo 2 l saman slétt.**"])},
    "Repeat_from_star_to_2star_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið frá * til ** út umferðina svo lykkjunum fækkar niður í ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), "."])},
    "Knit_x_rounds_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("numberOfRowsBetweenDecreases")), " umferðir án úrtöku."])},
    "Second_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. úrtökuumferð:"])},
    "Second_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l saman slétt og svo 1 l brugðið.**"])},
    "Second_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l saman slétt og svo 1 l brugðið.**"])},
    "Second_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l saman slétt og svo 1 l slétt.**"])},
    "Third_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. úrtökuumferð:"])},
    "Third_even_decrease_round_description_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var.**"])},
    "Third_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l saman slétt.**"])},
    "Final_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaúrtökuumferð:"])},
    "Final_even_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Prjónið 2 l saman slétt.**"])},
    "Repeat_from_star_to_2star_with_side_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið frá * til ** út umferðina svo lykkjunum fækkar niður í ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), _interpolate(_named("conditionalLastStitchSideNote")), "."])},
    "the_last_stitch_is_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (síðasta lykkjan er prjónuð stök)"])},
    "Break_the_yarn_and_pull_weave_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slítið bandið frá og dragið í gegn um lykkjurnar. Felið enda."])},
    "Cross_decrease_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nú er komið að úrtökunni, þar sem teknar eru úr 2 l á 4 stöðum yfir umferðina (8 l í heildina). Gott er að setja prjónamerki til að merkja hvar úrtakan fer fram."])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. umferð (úrtökuumferð):"])},
    "Back_of_the_head_left_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hnakki, vinstra megin:"])},
    "Work_x_stitches_in_x_stitch_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("typeOfStitch")), " í næstu ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " lykkjur."])},
    "stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slétt"])},
    "rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stroffprjón"])},
    "Decrease_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úrtaka:"])},
    "First_cross_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var. Prjónið því næst 2 lykkjur saman."])},
    "First_cross_decrease_round_description_rib": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið 2 lykkjur saman", _interpolate(_named("IntermediateRibStitches")), ". Takið 1 l óprjónaða, prjónið 1 l og steypið svo þeirri óprjónuðu yfir þá sem prjónuð var."])},
    "purl_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", prjónið 1 br"])},
    "work_x_stitches_in_rib_stitch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", prjónið ", _interpolate(_named("crossDecreaseColumnStitchesMinusTwo")), " lykkjur stroffprjón"])},
    "Left_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinstri hlið:"])},
    "Work_x_stitches_in_x_stitch_and_then_decrease": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("typeOfStitch")), " í næstu ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " lykkjur og framkvæmið svo úrtöku."])},
    "Forehead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enni:"])},
    "Right_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hægri hlið:"])},
    "Back_of_the_head_right_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hnakki, hægra megin:"])},
    "Now_the_number_of_stitches_has_decreased_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú ætti lykkjunum að hafa fækkað um 8, og eru orðnar ", _interpolate(_named("castOnStitchCountMinusEight")), " talsins."])},
    "Knit_the_round_in_x_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónið ", _interpolate(_named("typeOfStitch")), " án úrtaka."])},
    "Knit_a_decrease_round_except_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónið úrtökuumferð þar sem úrtakan fer fram á sömu stöðum og í 1. umferð (nema nú hefur lykkjunum á milli úrtaka fækkað um 2)."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Endurtakið síðustu tvær umferðir ", _interpolate(_named("everyOtherRowDecreaseCountMinusOne")), " sinnum til viðbótar, þangað til ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " lykkjur eru eftir á prjónunum í heildina."])},
    "Now_there_should_be_x_stitches_remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nú ættu að vera ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " lykkjur eftir samtals á prjónunum."])},
    "Work_a_decrease_in_every_round_until_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takið nú úr í hverri umferð þangað til ", _interpolate(_named("crossDecreaseFinalRowStitchCount")), " lykkjur eru eftir samtals á öllum prjónum. Slítið bandið frá og dragið í gegn um lykkjurnar. Felið enda."])}
  },
  "common4": {
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hönnun"])}
  },
  "navbar": {
    "Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppskriftir"])},
    "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkfæri"])},
    "Sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrá inn"])},
    "Sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrá út"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fara í áskrift"])}
  },
  "common_user_settings": {
    "First_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornafn"])},
    "Last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftirnafn"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykilorð"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nýtt lykilorð"])},
    "Confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykilorð endurtekið"])},
    "E_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netfang"])}
  },
  "sign_in": {
    "Not_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekki skráður notandi?"])},
    "Sign_up_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búðu til nýjan notanda."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrá inn"])},
    "Welcome_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomin(n) aftur!"])},
    "Forgot_password_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleymt lykilorð?"])},
    "Get_a_new_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fáðu nýtt."])}
  },
  "sign_up": {
    "Already_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nú þegar skráður notandi?"])},
    "Sign_in_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skráðu þig inn hér."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búa til notanda"])},
    "Suceess_Your_account_has_been_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skráning tókst!"])},
    "Your_free_trial_starts_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14 daga prufukeyrslan þín er hafin"])},
    "We_ve_sent_a_verification_email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Til staðfestingar höfum við sent póst á netfangið þitt. Kíktu í innhólfið þitt og ýttu á hlekkinn til að virkja aðganginn þinn."])},
    "Need_help_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vantar þig aðstoð?"])},
    "Contact_our_support_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heyrðu í okkur."])},
    "Create_a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búðu til notanda"])},
    "Knittable_can_send_me_marketing_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ég vil fá sendan innblástur fyrir prjónaverkefni og fá fréttir af prjónaverkfærum og nýjum hönnunum"])},
    "I_accept_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ég samþykki skilmála Knittable"])}
  },
  "verify_email": {
    "Verify_my_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staðfesta netfangið mitt"])},
    "Your_email_address_has_been_verified_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netfangið þitt hefur verið staðfest."])},
    "Sign_in_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skráðu þig inn til að halda áfram."])},
    "Click_here_to_resend_verification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endursenda staðfestingarpóst"])},
    "Verification_email_has_been_resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staðfestingarpóstur hefur verið sendur"])}
  },
  "user_profile": {
    "Reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endurstilla lykilorð"])},
    "Edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breyta notanda"])},
    "Delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyða notanda"])},
    "Are_you_sure_you_want_to_delete_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ertu viss um að þú viljir eyða þessum notanda?"])},
    "This_action_cannot_be_undone_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aðgerðin er óafturkræf."])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staðfesta"])},
    "User_Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notendastillingar"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyða"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endurstilla"])}
  },
  "common5": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hætta við"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senda"])}
  },
  "get_started": {
    "Your_knitting_design_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónaaðstoðin þín"])},
    "Unleash_your_creativity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frelsi til að skapa og "])},
    "Unleash_your_creativity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prjóna "])},
    "Unleash_your_creativity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uppúr þér"])},
    "Knittable_is_your_interactive_tool_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable tryggir örugga útkomu án útreikninga eða ágiskana þegar þú prjónar þína eigin hönnun. Allt sem þarf eru upplýsingar um prjónfestu, mál og Knittable útbýr uppskrift fyrir þig."])},
    "Try_it_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prófa"])}
  },
  "how_does_it_work": {
    "How_does_it_work_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvernig virkar þetta?"])},
    "1_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu garn"])},
    "2_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu stærð"])},
    "3_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byrjaðu að prjóna"])},
    "1_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable virkar fyrir allar garntegundir. Það eina sem þú þarft að gera er að slá inn prjónfestuna þína."])},
    "2_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldur úr stærðarlistanum eða sláðu inn mál ef þú vilt sérsniðna flík."])},
    "3_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taktu upp prjónana og byrjaðu að prjóna!"])},
    "1_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfesta er grundvöllurinn fyrir því að stærð flíkur sé í samræmi við málin sem lagt er upp með. Með Knittable ræður þú hver rétta prjónfestan fyrir verkefnið þitt er."])},
    "2_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Málin eru notuð til að reikna út hlutföll prjónaflíkarinnar að teknu tilliti til þæfingar og jákvæðs eða neikvæðs svigrúms."])},
    "3_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finndu gleðina og róna sem felst í að prjóna flík eftir þinni eigin hönnun vitandi að útkoman er örugg."])}
  },
  "pricing": {
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ókeypis"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mánaðarleg"])},
    "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Árleg"])},
    "yearly_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17% afsláttur"])},
    "paid_yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["á mánuði - $55 greitt árlega"])},
    "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velja"])},
    "Chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valin"])},
    "Next_payment_due_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Næsta greiðsla verður gjaldfærð ", _interpolate(_named("nextBillDate"))])},
    "Cancellation_effective_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Áskrift gildir út ", _interpolate(_named("cancellationEffectiveDate"))])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breyta"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hætta"])},
    "Payment_is_past_due_next_retry_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Síðasta greisla tókst ekki, næsta tilraun verður gerð ", _interpolate(_named("nextRetryDate"))])},
    "Subscription_expired_since": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Áskrift er útrunnin síðan ", _interpolate(_named("nextBillDate"))])},
    "Choose_what_fits_you_best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldu hvað hentar best"])},
    "Knitting_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónaverkfæri"])},
    "Pattern_add_on_for_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mynsturhluti fyrir verkfæri"])},
    "Interactive_knitting_patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gangvirkar prjónauppskriftir"])}
  },
  "our_patterns": {
    "Our_Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppskriftirnar"])},
    "Our_patterns_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér má finna grunnuppskriftir að algengum prjónaverkefnum sem þú getur aðlagað að hugmyndinni þinni"])},
    "Our_patterns_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiknivélin útbýr uppskriftir í réttri stærð fyrir allar gerðir af garni, bæði fyrir börn og fullorðna. Uppskriftirnar eru einfaldar, kjörnar fyrir byrjendur jafnt sem lengra komin sem vilja prófa sín eigin mynstur."])}
  },
  "subscription": {
    "Change_or_cancel_at_any_time_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þú getur breytt þessu vali hvenær sem í notendastillingunum þínum"])},
    "Go_back_to_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Til baka"])}
  },
  "gauge_from_swatch": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þessi reiknivél reiknar prjónfestuna þína út frá stærð prjónfestuprufu og fjölda lykkja og umferða í henni."])},
    "Swatch_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stærðir prjónfestuprufu"])},
    "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæð"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breidd"])},
    "Height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mæld hæð prufunnar (cm)"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mæld breidd prufunnar (cm)"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja á mældri breidd"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða á mældri hæð"])},
    "Height_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn hæð"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn breidd"])},
    "Swatch_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mældu breidd/hæð prjónfestuprufunnar og teldu samsvarandi lykkjur/umferðir."])},
    "Swatch_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prufan þarf ekki að vera 10 x 10 cm, en því stærri sem hún er, því nákvæmari er útreiknuð prjónfestan."])},
    "Your_knitting_gauge_is_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prjónfestan þín er ", _interpolate(_named("stitchGauge")), " lykkjur x ", _interpolate(_named("rowGauge")), " umferðir = 10 x 10 cm."])}
  },
  "common6": {
    "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reikna"])}
  },
  "welcome": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomin á knittable"])},
    "You_have_x_left_of_your_free_trial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Það eru ", _interpolate(_named("remainingDays")), " dagar eftir af prufukeyrslunni þinni"])},
    "View_subscription_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skoða áskriftarleiðir"])},
    "You_have_access_via_partnership_program_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Þú hefur fullan aðgang að Knittable sem samstarfsaðili þar til ", _interpolate(_named("expiresAt")), "."])},
    "You_have_access_via_partnership_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þú hefur fullan aðgang að Knittable sem samstarfsaðili."])},
    "Your_customers_can_use_the_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Viðskiptavinir þínir geta notað kóðann ", _interpolate(_named("code")), " til að fá 15% afslátt af fyrsta árinu í ársáskrift."])}
  },
  "tracking_behavior": {
    "We_would_like_to_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Við söfnum nafnlausum notkunarupplýsingum með vafrakökum og áþekkri tækni til að gera greiningar og bæta notendaupplifun. Er það í lagi?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])}
  },
  "pattern_form": {
    "A_new_version_is_available_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Það er ný útgáfa í boði fyrir þessa uppskrift. Kláraðu verk í vinnslu og uppfærðu fyrir næsta verkefni."])},
    "Upgrade_and_calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppfæra og reikna uppskrift"])}
  },
  "common7": {
    "Knit_calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónareiknivél"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fara í áskrift"])},
    "Subscribe_to_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virkja með áskrift"])},
    "Change_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfestubreytir"])}
  },
  "knit_calculator": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þessi reiknivél notar prjónfestuna þína til að reikna út hversu margar lykkjur eða umferðir þú þarft til að prjóna stykki af ákveðinni breidd eða lengd."])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lykkjur"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["umferðir"])},
    "Stitch_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja á 10 cm"])},
    "Row_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða á 10 cm"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breidd"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn breidd"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breidd stykkisins sem skal prjóna (cm)"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd"])},
    "Length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lengd"])},
    "Length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengd stykkisins sem skal prjóna (cm)"])},
    "Project_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkefnið þitt"])},
    "Project_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prjónfesta skiptist í lykkjufestu og umferðafestu. Lykkjufesta segir til um hversu margar lykkjur þarf til að prjóna stykki af breidd 10 cm og umferðafesta hversu margar umferðir þarf til að stykkið verði 10 cm langt."])},
    "Project_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykkju- eða umferðafjöldi er reiknaður út frá lykkju- eða umferðafestu og breidd eða lengd stykkis."])},
    "Pattern_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mynstur"])},
    "Pattern_repetition_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér geturðu slegið inn lykkjufjölda mynsturs og aukalykkjur til að bæta mynstri við útreikningana."])},
    "Pattern_repetition_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukalykkjur eru lykkur sem bæta á við útreikningana en eiga ekki að endurtakast, s.s. lykkjur til að klára mynstur eða kantlykkjur. Dæmi: ef kantur sitthvoru megin á stykki á að vera 5 lykkjur og 1 lykkju þarf til að klára mynstur þá eru aukalykkjurnar 5 + 5 + 1 = 11."])},
    "Pattern_repetition_information_bubble_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukalykkjur eru frekar notaðar þegar prjónað er fram og til baka, en eru yfirleitt 0 þegar prjónað er í hring."])},
    "Pattern_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykkjufjöldi"])},
    "Pattern_stitch_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lykkjufjölda"])},
    "Pattern_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja í hverri mynsturendurtekningu"])},
    "Additional_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukalykkjur"])},
    "Additional_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn aukalykkjur"])},
    "Additional_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja sem þarf til að klára mynstrið"])},
    "Pattern_row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umferðafjöldi"])},
    "Pattern_row_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn umferðafjölda"])},
    "Pattern_row_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða í hverri mynsturendurtekningu"])},
    "Additional_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukaumferðir"])},
    "Additional_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn aukaumferðri"])},
    "Additional_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða sem þarf til að klára mynstrið"])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " lykkjur jafngilda ", _interpolate(_named("width")), " cm."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " umferðir jafngilda ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " lykkjur jafngilda ", _interpolate(_named("width")), " cm (", _interpolate(_named("widthDifference")), " cm). Það eru ", _interpolate(_named("patternRepetition")), " mynsturendurtekningar og ", _interpolate(_named("patternAdditionalCount")), " aukalykkja/ur."])},
    "Rows_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " umferðir jafngilda ", _interpolate(_named("length")), " cm (", _interpolate(_named("lengthDifference")), " cm). Það eru ", _interpolate(_named("patternRepetition")), " mynsturendurtekningar og ", _interpolate(_named("patternAdditionalCount")), " aukaumferð(ir)."])}
  },
  "gauge_converter": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þessi reiknivél umbreytir lykkju- eða umferðafjölda til að gefa sömu breidd eða lengd með nýrri prjónfestu. Hún getur hjálpað til við að skipta um garntegund í uppskrift."])},
    "Current_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér er átt við prjónfestuna sem þú ert að breyta yfir í og ætlar að nota fyrir verkefnið þitt."])},
    "Original_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hér er átt við upprunalegu prjónfestuna, þá sem þú ert að breyta úr. Þetta getur t.d. verið prjónfesta úr uppskrift og tilheyrandi lykkju- eða umferðafjöldi."])},
    "Current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkefnið þitt"])},
    "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprunaleg"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykkjufjöldinn sem þú vilt breyta"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umferðafjöldinn sem þú vilt breyta"])},
    "Stitch_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Núverandi lykkjufjöldi á 10 cm"])},
    "Row_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Núverandi umferðafjöldi 10 cm"])},
    "Stitch_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprunalegur lykkjufjöldi á 10 cm"])},
    "Row_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upprunalegur umferðafjöldi 10 cm"])},
    "Original_stitch_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upprunalega breiddin er ", _interpolate(_named("width")), " cm."])},
    "Original_row_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upprunalega lengdin er ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Þetta jafngildir ", _interpolate(_named("stitchCount")), " lykkjum með núverandi prjónfestu."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Þetta jafngildir ", _interpolate(_named("rowCount")), " umferðum með núverandi prjónfestu."])}
  },
  "common_tools": {
    "Calculate_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reikna fyrir"])},
    "Stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lykkjufesta"])},
    "Stitch_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn lykkjufestu"])},
    "Row_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umferðafesta"])},
    "Row_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn umferðafestu"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi lykkja"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjöldi umferða"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn fjölda lykkja"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sláðu inn fjölda umferða"])}
  },
  "forgot_password": {
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áfram"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nýtt lykilorð"])},
    "A_new_password_has_successfully_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þetta tókst! Smelltu á hlekkinn sem þú fékkst sendan til að endursetja lykilorðið þitt."])},
    "Password_successfully_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nýja lykilorðið þitt hefur verið vistað."])}
  },
  "sweater": {
    "Finished_measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Tilbúin mál"])},
    "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stærð"])},
    "Requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pöntuð stærð"])},
    "Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raunstærð"])},
    "Difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munur"])}
  }
}