<template>
  <h3 class="app-page-title">{{ $t("common7.Change_gauge") }}</h3>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <v-form class="app-pattern-form" @submit.prevent="Calculate">
          <div class="app-form-description">{{ $t("gauge_converter.tool_description") }}</div>
          <div class="app-pattern-form-section">
            <calculate-for-entity v-model:useStitches="formData.useStitches"></calculate-for-entity>
          </div>

          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("gauge_converter.Original") }}
              </div>
              <information-bubble v-bind:colorsInverted="true" position="right" width="medium">
                <p>{{ $t("gauge_converter.Original_information_bubble_1") }}</p>
                <p>{{ $t("knit_calculator.Project_dimensions_information_bubble_1") }}</p>
              </information-bubble>
            </header>

            <div v-if="formData.useStitches" class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.originalEntityGauge"
                :label="$t('common_tools.Stitch_gauge')"
                :icon="'mdi-reorder-vertical'"
                :description="$t('gauge_converter.Stitch_gauge_original_description')"
                :placeholder="$t('common_tools.Stitch_gauge_placeholder')"
                :small="true"
                :step="1"
              ></input-field>

              <v-spacer></v-spacer>

              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.originalEntityCount"
                :label="$t('common_tools.Number_of_stitches')"
                :description="$t('gauge_converter.Number_of_stitches_description')"
                :placeholder="$t('common_tools.Number_of_stitches_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
            </div>

            <div v-else class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.originalEntityGauge"
                :label="$t('common_tools.Row_gauge')"
                :icon="'mdi-reorder-horizontal'"
                :description="$t('gauge_converter.Row_gauge_original_description')"
                :placeholder="$t('common_tools.Row_gauge_placeholder')"
                :small="true"
                :step="1"
              ></input-field>

              <v-spacer></v-spacer>

              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.originalEntityCount"
                :label="$t('common_tools.Number_of_rows')"
                :description="$t('gauge_converter.Number_of_rows_description')"
                :placeholder="$t('common_tools.Number_of_rows_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
            </div>
          </div>

          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("gauge_converter.Current") }}
              </div>
              <information-bubble v-bind:colorsInverted="true" position="bottom" width="medium">
                <p>{{ $t("gauge_converter.Current_information_bubble_1") }}</p>
                <p>{{ $t("knit_calculator.Project_dimensions_information_bubble_1") }}</p>
              </information-bubble>
            </header>

            <div v-if="formData.useStitches" class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.currentEntityGauge"
                :label="$t('common_tools.Stitch_gauge')"
                :icon="'mdi-reorder-vertical'"
                :description="$t('gauge_converter.Stitch_gauge_current_description')"
                :placeholder="$t('common_tools.Stitch_gauge_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
            </div>

            <div v-else class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.currentEntityGauge"
                :label="$t('common_tools.Row_gauge')"
                :icon="'mdi-reorder-horizontal'"
                :description="$t('gauge_converter.Row_gauge_current_description')"
                :placeholder="$t('common_tools.Row_gauge_placeholder')"
                :small="true"
                :step="1"
              ></input-field>
            </div>
          </div>

          <div class="app-pattern-form-section">
            <pattern-repetition-form
              v-model:pattern-repetition-count="formData.patternEntityCount"
              v-model:pattern-additional-count="formData.patternAdditionalCount"
              :use-stitches="formData.useStitches"
              :is-subscribed-or-on-trial="isSubscribedOrOnTrial"
              @go-to-subscription="goToSubscription"
            ></pattern-repetition-form>
          </div>

          <pattern-form-button :label="$t('common6.Calculate')" :disabled="!buttonIsEnabled">
          </pattern-form-button>
        </v-form>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showResults">
        <gauge-converter-result
          :disabled="formHasChanges"
          :use-stitches="resultUseStitches"
          :data="resultData"
          :wanted-dimension="dimension"
        ></gauge-converter-result>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import CalculateForEntity from "./../../components/tools/CalculateForEntity.vue";
import { composeRoute } from "../../lib/navigationUtils";
import GaugeConverterResult from "./../../components/tools/GaugeConverterResult.vue";
import InformationBubble from "../../components/InformationBubble.vue";
import InputField from "./../../components/general/InputField.vue";
import PatternFormButton from "./../../components/patterns/PatternFormButton.vue";
import PatternRepetitionForm from "./../../components/tools/PatternRepetitionForm.vue";
import { checkIfUserHasAccess } from "./../../services/access.js";
import { loadCurrentSettings, saveCurrentSettings } from "./../../services/toolSettings";
import { calculateKnit, ResultProperties } from "./../../services/tool.js";
import { useLangStore } from "./../../stores/lang";
import { roundToBase } from "../../lib/calculationUtils";

const toolType = "gaugeconverter";
const toolVersion = "1.0.0";

const router = useRouter();

const langStore = useLangStore();
const locale = langStore.getLocale();

const formData = ref({
  useStitches: true,
  originalEntityGauge: null,
  originalEntityCount: null,
  currentEntityGauge: null,
  patternEntityCount: 1,
  patternAdditionalCount: 0,
});

const showResults = ref(false);
const formHasChanges = ref(false);
const loading = ref(false);

const dimension = ref(null);
const currentEntityCount = ref(null);

let resultData = ref(null);
let resultUseStitches = ref(null);

const isSubscribedOrOnTrial = ref(null);

const formIsValid = computed(() => {
  const {
    originalEntityGauge,
    currentEntityGauge,
    originalEntityCount,
    patternEntityCount,
    patternAdditionalCount,
  } = formData.value;
  return (
    originalEntityGauge &&
    currentEntityGauge &&
    originalEntityCount &&
    patternEntityCount &&
    patternAdditionalCount !== null
  );
});

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

onMounted(async () => {
  try {
    const loadedForm = await loadCurrentSettings(toolType, toolVersion);
    isSubscribedOrOnTrial.value = await checkIfUserHasAccess();

    if (loadedForm != null) {
      formData.value = loadedForm;
      if (isSubscribedOrOnTrial.value) {
        Calculate();
      }
    }
  } catch (error) {
    alert(error);
  }
});

const Calculate = async () => {
  if (!formIsValid.value) {
    return;
  }

  try {
    loading.value = true;
    resultUseStitches.value = formData.value.useStitches;

    dimension.value = roundToBase(
      (formData.value.originalEntityCount / formData.value.originalEntityGauge) * 10,
      0.5
    );

    if (!isSubscribedOrOnTrial.value || formData.value.patternEntityCount == 1) {
      currentEntityCount.value = (formData.value.currentEntityGauge * dimension.value) / 10;
      resultData.value = [
        new ResultProperties(
          (formData.value.currentEntityGauge * dimension.value) / 10,
          dimension.value
        ),
      ];
    } else {
      resultData.value = await calculateKnit(
        formData.value.currentEntityGauge / 10,
        dimension.value,
        formData.value.patternEntityCount,
        formData.value.patternAdditionalCount
      );
    }

    await saveCurrentSettings(toolType, toolVersion, formData.value);
  } catch (error) {
    alert(error);
  } finally {
    formHasChanges.value = false;
    showResults.value = true;
    loading.value = false;
  }
};

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const goToSubscription = async () => {
  await saveCurrentSettings(toolType, toolVersion, formData.value);
  router.push(composeRoute(locale.value, "subscription", "knitcalculator"));
};
</script>

<style lang="scss" scoped>
@import "./../../../scss/tool.scss";
@import "./../../../scss/form.scss";
</style>
