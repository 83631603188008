<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("common.Sweater") }}</h3>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <div class="app-form-warning">{{ $t("sweater.In_development_warning") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Size") }}
              </div>
            </header>

            <persons-select-field
              v-model="formData.chosenPerson"
              :include-gendered-options="true"
              @item-selected="(item) => onPersonSelected(formData, item.value)"
            >
            </persons-select-field>

            <select-field
              v-if="loadedProject | (currentStep >= 1)"
              v-model="formData.chosenSize"
              ref="selectSizesField"
              :label="t('common3.Size_')"
              :description="t('sweater.Size_description')"
              :placeholder="t('common3.Choose_a_size')"
              :items="sizes"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onSizeSelected(formData, item.value)"
            ></select-field>

            <select-field
              v-if="loadedProject | (currentStep >= 2)"
              v-model="formData.chosenFit"
              ref="selectSizesField"
              :label="t('sweater.Fit')"
              :placeholder="t('sweater.Choose_fit')"
              :items="fitOptions"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onFitSelected(formData, item.value)"
            ></select-field>

            <select-field
              v-if="loadedProject | (currentStep >= 3)"
              v-model="formData.chosenLength"
              ref="selectSizesField"
              :label="t('sweater.Length')"
              :placeholder="t('sweater.Choose_length')"
              :items="lengthOptions"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onLengthSelected(formData, item.value)"
            ></select-field>

            <advanced-settings
              :title="t('common.Advanced_settings')"
              :disabled="!showAdvancedSettings"
              v-if="loadedProject | (currentStep >= 4)"
            >
              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Neckband") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.neckbandCircumf"
                  :label="t('sweater.Neckband_circumf_in_cm_')"
                  :description="t('sweater.Neckband_circumf_description')"
                  :placeholder="t('sweater.Type_in_neckband_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.neckbandLength"
                  :label="t('sweater.Neckband_length_in_cm_')"
                  :description="t('sweater.Neckband_length_description')"
                  :placeholder="t('sweater.Type_in_neckband_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Body") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.chestCircumf"
                  :label="t('sweater.Chest_circumf')"
                  :description="t('sweater.Chest_circumf_description')"
                  :placeholder="t('sweater.Type_in_chest_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.easeBody"
                  :label="t('sweater.Body_ease')"
                  :description="t('sweater.Body_ease_description')"
                  :placeholder="t('sweater.Type_in_body_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.finishedChestCircumf"
                  :label="t('sweater.Finished_chest_circumf')"
                  :description="t('sweater.Finished_chest_circumf_description')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.hemLength"
                  :label="t('sweater.Hem_length_in_cm_')"
                  :description="t('sweater.Hem_length_description')"
                  :placeholder="t('sweater.Type_in_hem_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :step="1"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.sweaterLength"
                  :label="t('sweater.Sweater_length_in_cm_')"
                  :description="t('sweater.Sweater_length_description')"
                  :placeholder="t('sweater.Type_in_sweater_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Sleeves") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.armCircumf"
                  :label="t('sweater.Arm_circumf')"
                  :description="t('sweater.Arm_circumf_description')"
                  :placeholder="t('sweater.Type_in_arm_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.armLength"
                  :label="t('sweater.Arm_length_in_cm_')"
                  :description="t('sweater.Arm_length_description')"
                  :placeholder="t('sweater.Type_in_arm_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.easeSleeve"
                  :label="t('sweater.Sleeve_ease')"
                  :description="t('sweater.Sleeve_ease_description')"
                  :placeholder="t('sweater.Type_in_sleeve_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.sleeveCircumf"
                  :label="t('sweater.Sleeve_circumf')"
                  :description="t('sweater.Sleeve_circumf_description')"
                  :placeholder="t('sweater.Type_in_sleeve_circumf')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.wristCircumf"
                  :label="t('sweater.Wrist_circumf')"
                  :description="t('sweater.Wrist_circumf_description')"
                  :placeholder="t('sweater.Type_in_wrist_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="formData.cuffLength"
                  :label="t('sweater.Cuff_length_in_cm_')"
                  :description="t('sweater.Cuff_length_description')"
                  :placeholder="t('sweater.Type_in_cuff_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :step="1"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 4)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <ribbing-toggle-field
              v-model="formData.patternRibStitchCount"
              v-if="loadedProject | (currentStep >= 4)"
            >
            </ribbing-toggle-field>

            <div class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.raglanStitchCount"
                :label="t('sweater.Raglan_stitch_count')"
                :description="t('sweater.Raglan_stitch_count_description')"
                :placeholder="t('sweater.Type_in_raglan_stitch_count')"
                :disabled="valuesDisabled"
                :small="true"
                :step="1"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.patternBodyStitchCount"
                :label="t('sweater.Body_pattern_repeat')"
                :description="t('sweater.Body_pattern_repeat_description')"
                :placeholder="t('sweater.Type_in_body_pattern_repeat')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
            </div>
          </div>

          <div class="app-pattern-form-section" v-if="loadedProject | (currentStep >= 5)">
            <knitting-gauge
              v-model:row-gauge="formData.rowGauge"
              v-model:stitch-gauge="formData.stitchGauge"
              v-model:estimate-row-gauge-enabled="formData.estimateRowGaugeEnabled"
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="newVersionAvailable">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showPattern">
        <pattern-renderer
          :current-version="currentVersion"
          :versions="versions"
          :disabled="buttonIsEnabled"
          v-model:bookmark="bookmark"
          :data="sweaterData"
        ></pattern-renderer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateSweater } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import {
  sizesSelectFieldDisabled,
  measurementFieldsDisabled,
  hasEmptyValues,
} from "../../lib/patternFormUtils";
import { loadCurrentProject, saveCurrentProject } from "../../services/project";
import { useLangStore } from "./../../stores/lang";
import { roundToBase } from "./../../lib/calculationUtils.js";
import RibbingToggleField from "../../components/patterns/RibbingToggleField.vue";

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();

const router = useRouter();

const patternType = "sweater";
const bookmark = ref(null);

const latestVersion = "0.1.0";
const currentVersion = ref(latestVersion);
const versions = {
  "0.1.0": "SweaterPattern",
};

const { t } = useI18n();

const formData = ref({
  chosenPerson: null,
  chosenSize: null,
  chosenFit: null,
  chosenLength: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  finishedChestCircumf: null,
  chestCircumf: null,
  easeBody: null,
  cuffLength: null,
  wristCircumf: null,
  neckbandCircumf: null,
  neckbandLength: null,
  sleeveCircumf: null,
  armCircumf: null,
  easeSleeve: null,
  armLength: null,
  hemLength: null,
  patternRibStitchCount: null,
  raglanStitchCount: null,
  patternBodyStitchCount: 1,
  yokeType: "raglan",
  sweaterLength: null,
  sleeveType: "regular",
});

const selectSizesField = ref(null);
const sizes = ref(null);
let sweaterData = ref(null);

const sizeSelectorDisabled = computed(() => sizesSelectFieldDisabled(formData.value));
const valuesDisabled = computed(() => measurementFieldsDisabled(formData.value));

const formHasChanges = ref(false);
const showPattern = ref(false);
const currentStep = ref(0);
const loadedProject = ref(false);

const isSubscribedOrOnTrial = ref(true);

watch(
  () => formData.value.chosenPerson,
  () => {
    if (selectSizesField.value) {
      selectSizesField.value.resetSelection();
    }
    switch (formData.value.chosenPerson.value) {
      // case "child":
      //   sizes.value = sizeBabyChildYouth.value;
      //   break;
      // case "man":
      //   sizes.value = sizeMan.value;
      //   break;
      case "women":
        sizes.value = sizeWomen.value;
        break;
      // case "unisex":
      //   sizes.value = sizeUnisex.value;
      //   break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(formData, newChosenPerson) {
  if (newChosenPerson == "custom") {
    formData.chosenSize = "custom";
  } else {
    formData.chosenSize = null;
  }

  resetSizes();

  currentStep.value = Math.max(currentStep.value, 1);
}

const resetSizes = () => {
  formData.value.neckbandCircumf = null;
  formData.value.chestCircumf = null;
  formData.value.armCircumf = null;
  formData.value.neckbandLength = null;
  formData.value.cuffLength = null;
  formData.value.hemLength = null;
};

function onSizeSelected(formData, newChosenSize) {
  formData.neckbandCircumf = newChosenSize.sizeNeckbandCircumf;
  formData.chestCircumf = newChosenSize.sizeChestCircumf;
  formData.armCircumf = newChosenSize.sizeArmCircumf;
  formData.armLength = newChosenSize.sizeArmLength;
  formData.wristCircumf = newChosenSize.sizeWristCircumf;
  formData.neckbandLength = 4;
  formData.cuffLength = 5;
  formData.hemLength = 5;

  currentStep.value = Math.max(currentStep.value, 2);
}

function onFitSelected(formData, newChosenFit) {
  formData.easeBody = newChosenFit.easeBody;
  formData.easeSleeve = newChosenFit.easeSleeve;

  currentStep.value = Math.max(currentStep.value, 3);
}

function onLengthSelected(formData, newChosenLength) {
  switch (newChosenLength) {
    case "cropped":
      formData.sweaterLength = calculateCroppedLength(formData.chosenSize.value.sizeChestCircumf);
      break;
    case "regular":
      formData.sweaterLength = calculateRegularLength(formData.chosenSize.value.sizeChestCircumf);
      break;
    case "long":
      formData.sweaterLength = calculateLongLength(formData.chosenSize.value.sizeChestCircumf);
      break;
    default:
      formData.sweaterLength = null;
      break;
  }
  currentStep.value = Math.max(currentStep.value, 4);
}

onMounted(async () => {
  const project = await loadCurrentProject(patternType);
  if (project != null) {
    Object.assign(formData.value, project.settings);
    bookmark.value = project.bookmark;
    currentVersion.value = project.patternVersion;
    calculatePattern(false);
    loadedProject.value = true;
  }
});

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const formIsValid = computed(() => {
  const validFormData = { ...formData.value };

  // easeBody and easeSleeve are allowed to be 0
  if (validFormData.easeBody === 0) {
    delete validFormData.easeBody;
  }
  if (validFormData.easeSleeve === 0) {
    delete validFormData.easeSleeve;
  }

  return !hasEmptyValues(validFormData);
});

const newVersionAvailable = computed(() => {
  return currentVersion.value != latestVersion;
});

const calculatePattern = async (upgrade) => {
  try {
    loading.value = true;
    if (upgrade) {
      currentVersion.value = latestVersion;
    }
    if (formIsValid.value) {
      await getSweaterParameters();
      formHasChanges.value = false;
      await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getSweaterParameters() {
  const sweaterParameters = await calculateSweater(
    formData.value.stitchGauge / 10,
    formData.value.rowGauge / 10,
    formData.value.chestCircumf,
    formData.value.easeBody,
    formData.value.wristCircumf,
    formData.value.cuffLength,
    formData.value.neckbandCircumf,
    formData.value.neckbandLength,
    formData.value.armCircumf,
    formData.value.armLength,
    formData.value.easeSleeve,
    formData.value.patternRibStitchCount.value,
    formData.value.raglanStitchCount,
    formData.value.patternBodyStitchCount,
    formData.value.yokeType,
    formData.value.sweaterLength,
    formData.value.hemLength,
    formData.value.sleeveType
  );

  showPattern.value = true;
  sweaterData.value = sweaterParameters;
  return sweaterParameters;
}

const goToSubscription = async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
  router.push(composeRoute(locale.value, "subscription", "sweater"));
};

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

watch(bookmark, async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
});

const calculateCroppedLength = (chestCircumf) => {
  // based on sweater length from several cropped sweater patterns
  return roundToBase(0.247 * chestCircumf + 26, 0.5);
};

const calculateRegularLength = (chestCircumf) => {
  // based on sweater length from several regular sweater patterns
  return roundToBase(0.28 * chestCircumf + 30.2, 0.5);
};

const calculateLongLength = (chestCircumf) => {
  // based on sweater length from several long sweater patterns
  return roundToBase(0.123 * chestCircumf + 59.5, 0.5);
};

const personSizeIsSelected = computed(() => {
  return formData.value.chosenPerson != null && formData.value.chosenPerson.value != "custom";
});

const customSizeIsSelected = computed(() => {
  return formData.value.chosenPerson != null && formData.value.chosenPerson.value == "custom";
});

const showAdvancedSettings = computed(() => {
  if (customSizeIsSelected.value) {
    return true;
  }
  if (
    personSizeIsSelected &&
    formData.value.chosenSize != null &&
    formData.value.chosenFit != null &&
    formData.value.chosenLength != null
  ) {
    return true;
  }

  return false;
});

watch(
  () => formData.value.patternRibStitchCount,
  (value) => {
    // TODO: Add a warning if raglan stitch count and pattern rib 
    // stitch count do not go together
    if (!formData.value.raglanStitchCount) {
      formData.value.raglanStitchCount = value.value == 2 ? 1 : 2;
    }
    currentStep.value = Math.max(currentStep.value, 5);
  }
);

watch(
  () => [formData.value.chestCircumf, formData.value.easeBody],
  ([newChestCircumf, newEaseBody]) => {
    if (newChestCircumf == null || newEaseBody == null) {
      formData.value.finishedChestCircumf = null;
    } else {
      formData.value.finishedChestCircumf = newChestCircumf + newEaseBody;
    }
  }
);

watch(
  () => [formData.value.armCircumf, formData.value.easeSleeve],
  ([newArmCircumf, newEaseSleeve]) => {
    if (newArmCircumf == null || newEaseSleeve == null) {
      formData.value.sleeveCircumf = null;
    } else {
      formData.value.sleeveCircumf = newArmCircumf + newEaseSleeve;
    }
  }
);

watch(
  () => [formData.value.chestCircumf, formData.value.easeBody],
  ([newChestCircumf, newEaseBody]) => {
    formData.value.finishedChestCircumf = newChestCircumf + newEaseBody;
  }
);

watch(
  () => [formData.value.armCircumf, formData.value.easeSleeve],
  ([newArmCircumf, newEaseSleeve]) => {
    formData.value.sleeveCircumf = newArmCircumf + newEaseSleeve;
  }
);

const fitOptions = computed(() => {
  return [
    {
      key: t("sweater.Fitted") + " (-5 cm ease)",
      value: {
        easeBody: -5,
        easeSleeve: -2.5,
      },
    },
    {
      key: t("sweater.Tailored") + " (0 cm ease)",
      value: {
        easeBody: 0,
        easeSleeve: 0,
      },
    },
    {
      key: t("sweater.Relaxed") + " (5 cm ease)",
      value: {
        easeBody: 5,
        easeSleeve: 2.5,
      },
    },
    {
      key: t("sweater.Loose") + " (15 cm ease)",
      value: {
        easeBody: 15,
        easeSleeve: 7.5,
      },
    },
    {
      key: t("sweater.Oversized") + " (25 cm ease)",
      value: {
        easeBody: 25,
        easeSleeve: 12.5,
      },
    },
  ];
});
const lengthOptions = computed(() => {
  return [
    {
      key: t("sweater.Cropped"),
      value: "cropped",
    },
    {
      key: t("sweater.Regular"),
      value: "regular",
    },
    {
      key: t("sweater.Long"),
      value: "long",
    },
  ];
});

const sizeWomen = computed(() => {
  return [
    {
      key: "1 (" + t("sweater.chest_circumference") + " 75-80 cm)",
      value: {
        sizeNeckbandCircumf: 37,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 14,
        sizeChestCircumf: 77.5,
        sizeArmLength: 48.0,
      },
    },
    {
      key: "2 (" + t("sweater.chest_circumference") + " 80-85 cm)",
      value: {
        sizeNeckbandCircumf: 38,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 15,
        sizeChestCircumf: 82.5,
        sizeArmLength: 48.5,
      },
    },
    {
      key: "3 (" + t("sweater.chest_circumference") + " 85-90 cm)",
      value: {
        sizeNeckbandCircumf: 39,
        sizeArmCircumf: 27.5,
        sizeWristCircumf: 16,
        sizeChestCircumf: 87.5,
        sizeArmLength: 49.0,
      },
    },
    {
      key: "4 (" + t("sweater.chest_circumference") + " 90-95 cm)",
      value: {
        sizeNeckbandCircumf: 40,
        sizeArmCircumf: 29.0,
        sizeWristCircumf: 17,
        sizeChestCircumf: 92.5,
        sizeArmLength: 50.0,
      },
    },
    {
      key: "5 (" + t("sweater.chest_circumference") + " 95-100 cm)",
      value: {
        sizeNeckbandCircumf: 41.5,
        sizeArmCircumf: 31.0,
        sizeWristCircumf: 18,
        sizeChestCircumf: 97.5,
        sizeArmLength: 50.0,
      },
    },
    {
      key: "6 (" + t("sweater.chest_circumference") + " 100-110 cm)",
      value: {
        sizeNeckbandCircumf: 42.5,
        sizeArmCircumf: 32.0,
        sizeWristCircumf: 19,
        sizeChestCircumf: 105,
        sizeArmLength: 49.0,
      },
    },
    {
      key: "7 (" + t("sweater.chest_circumference") + " 110-120 cm)",
      value: {
        sizeNeckbandCircumf: 43,
        sizeArmCircumf: 34.0,
        sizeWristCircumf: 20,
        sizeChestCircumf: 115,
        sizeArmLength: 48.0,
      },
    },
    {
      key: "8 (" + t("sweater.chest_circumference") + " 120-130 cm)",
      value: {
        sizeNeckbandCircumf: 43.5,
        sizeArmCircumf: 36.0,
        sizeWristCircumf: 21,
        sizeChestCircumf: 125,
        sizeArmLength: 48.0,
      },
    },
    {
      key: "9 (" + t("sweater.chest_circumference") + " 130-140 cm)",
      value: {
        sizeNeckbandCircumf: 44,
        sizeArmCircumf: 38.0,
        sizeWristCircumf: 22,
        sizeChestCircumf: 135,
        sizeArmLength: 46.0,
      },
    },
    {
      key: "10 (" + t("sweater.chest_circumference") + " 140-150 cm)",
      value: {
        sizeNeckbandCircumf: 44.5,
        sizeArmCircumf: 40.0,
        sizeWristCircumf: 23,
        sizeChestCircumf: 145,
        sizeArmLength: 45,
      },
    },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
