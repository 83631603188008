export default {
  "common": {
    "Beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hue"])},
    "Mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanter"])},
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strømper"])},
    "Sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweater"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg størrelseskategori"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Størrelseskategori"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barn"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voksen"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skræddersyet"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måneder"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["år"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregn opskrift"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkefasthed fra strikkeprøve"])},
    "Men": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herre"])},
    "Women": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvinder"])},
    "Advanced_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede indstillinger"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rib"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkelt (1 r, 1 vr)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobbelt (2 r, 2 vr)"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine ideer kan strikkes"])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkeberegninger kan være komplicerede og tidskrævende. På islandsk har vi et ordsprog: \"Drjúg er lykkjustundin\", som betyder, at den tid, man bruger på at strikke, er godt givet ud. Med Knittable kan du minimere forberedelsestiden og maksimere strikketiden."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoreret af"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkefasthed"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskeantal"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindeantal"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkefasthed angiver antal masker og antal pinde på et strikket areal på 10 x 10 cm."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det meste garn angiver en strikkefasthed for en bestemt pindestørrelse. Hvis du strikker gennemsnitligt stramt, kan du med vis sikkerhed bruge den vejledende pindestørrelse."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du plejer at strikke løsere eller strammere end gennemsnittet, kan du eventuelt bruge større eller mindre pinde end dem, som garnproducenten foreslår. Dog er det altid sikrest at kontrollere strikkefastheden med en strikkeprøve."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal masker per 10 cm"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fx. 18 masker med Léttlopi"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal pinde per 10 cm"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fx 24 pinde med Léttlopi"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslå antal pinde ud fra antal masker"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skostørrelse"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg skostørrelse"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankelomkreds"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omkreds om ankel (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ankelomkreds"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fodomkreds"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fodomkreds ved fodbalder (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast fodomkreds"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strømpehøjde"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strømpehøjde målt fra hælen og op ad skaftet (cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast Strømpehøjde"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strømpelængde"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strømpelængde målt fra hæl til tå (cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast strømpelængde"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaft"])},
    "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("castOnStitchCount")), " m op og fordel dem på fire pinde, med den pindestørrelse, der giver den rigtige strikkefasthed (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m). Strik rundt i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), " vr), indtil arbejdet måler ", _interpolate(_named("cuffLength")), " cm."])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("anklePieceLength")), " cm rundt i glatstrik"])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæl"])},
    "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu strikkes hælen. Strik 1. pind glat, vend, tag den første m løst af og strik vr pind ud. Sæt samtidigt masker fra 1. og 4. pind på én pind (i alt ", _interpolate(_named("castOnStitchCount_half")), " m), som vil danne hælen. Strik glatstrik over alle hælmaskerne (ret fra retsiden og vrang fra vrangsiden, den første m tages altid løst af), i alt ", _interpolate(_named("heelFlapRowCount")), " pinde, når pinde tælles fra den pind, som før var 4. pind. Næste pind er fra retsiden."])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernæst strikkes hælindtagninger"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. pind med hælindtagninger:"])},
    "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag den første m løst af og strik r, indtil der er ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " hælmasker tilbage. Tag derefter 1 m løst af, strik 1 m, og træk den løse m over den strikkede m, før arbejdet vendes."])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. pind:"])},
    "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag den første m løst af, strik vr (ret på retsiden), indtil der er ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " hælmasker tilbage, strik 2 m vr sammen, før arbejdes vendes."])},
    "Heel_paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag 1. og 2. pind men hvor der er ", _interpolate(_named("heelFlapMarginStitchCount")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusOne")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusTwo")), " osv m tilbage, indtil alle kantmasker er blevet samlet."])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fod"])},
    "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt igen. Fordel hælmasker på to pinde og saml alle ", _interpolate(_named("heelFlapEdgeStitchCount")), " kantmasker op på hver sin side af hælen over på de to pinde. For at undgå huller, samles én ekstra m op på hver pind ved hælens begyndelse (masker fra omg, før hælen starter)."])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik de to første omg rundt således:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik rundt i glatstrik. For at undgå huller ved hælen, anbefales det at strikke de nye masker drejet fra hælens kant"])},
    "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik r fra 1. pind, indtil der er 3 m tilbage på pinden, strik 2 m sammen og derefter pindens sidste m. Strik r over m på 2. og 3. pind. Strik den første m ret fra 4. pind, tag 1 m løst af, strik 1 m og træk den løse m over den strikkede m. Strik ret omg ud."])},
    "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag 2. og 3. omg, indtil der er ", _interpolate(_named("footStitchCount")), " m tilbage."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik glatstrik, indtil strømpen måler ", _interpolate(_named("footLengthMinusTipLength")), " cm, målt fra hælen."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tå"])},
    "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at det kombinerede antal masker på 1. og 4. pind er det samme som på 2. og 3. pind."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagninger til tåen strikkes således:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. omg (med indtagninger):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Strik r, indtil der er ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " m tilbage. Strik 2 m r sammen", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", og strik derefter ", _interpolate(_named("tipMarginStitchCount")), " m r"])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Tag den første m løst af, strik 1 m r og træk den løse m over den strikkede m. Strik r p ud."])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik 1 m r."])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Strik som 1. pind"])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Strik som 2. pind"])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Herefter strikkes indtagninger, indtil der er ", _interpolate(_named("finalRowStitchCount")), " m tilbage. Bryd garnet og træk enden gennem de resterende masker. Hæft løse ender."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik den anden strømpe på samme måde."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("castOnStitchCount")), " m op på fire strømpepinde (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m) eller rundpind. Saml arbejdet og strik rundt i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), " vr), indtil arbejdet måler ", _interpolate(_named("cuffLength")), " cm i længden."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu strikkes hælflappen. Strik ", _interpolate(_named("heelflapStitchCountLeft")), " m fra 1. pind, vend arbejdet, og tag den første m vr løst af. Strik de resterende ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " m vr, fra den 1. pind og sæt maskemarkør. Strik ", _interpolate(_named("heelflapStitchCountRight")), " m vr fra den 4. pind over på den 1. pind. Der skal være ", _interpolate(_named("heelflapStitchCount")), " m på 1. pind, som danner hælflappens begyndelse. De resterende ", _interpolate(_named("instepStitchCount")), " m danner vristen."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fortsæt frem og tilbage over disse ", _interpolate(_named("heelflapStitchCount")), " m i glatstrik (strik ret på retsiden, og vrang på vrangsiden, tag altid den første m løst af), indtil ", _interpolate(_named("heelFlapRowCount")), " hælflappinde er strikket, når man tæller fra højre side af maskemarkøren. Der skulle nu være ", _interpolate(_named("heelFlapEdgeStitchCount")), " kantmasker på hver side af hælflappen, og næste pind er fra retsiden."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag den første m r løst af og strik r, indtil maskemarkør. Strik ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " m r. Tag en m r løst af, 1 r, træk den løse m over den strikkede m, og vend arbejdet."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag den første m vr løst af og strik vr, indtil maskemarkør. Strik ", _interpolate(_named("heelFlapCenterRightMinusOne")), " m vr. Strik 2 m vr sammen, og vend arbejdet."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentag 1. og 2. pind, indtil alle sidemasker er strikket."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag den første m ret løst af og strik ", _interpolate(_named("heelFlapCenterRightMinusOne")), " m for at nå maskemarkøren."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resten af foden strikkes rundt. Sæt hælflappemaskerne tilbage på to pinde ved maskemarkør og saml alle ", _interpolate(_named("heelFlapEdgeStitchCount")), " kantmasker op med disse pinde på hver side af hælflappen. Tilføj 1 m mere på hver p ved at samle 1 m op i starten af hælflappen (der stammer fra sidste p før hælflappen). Begyndelsen af omg er stadig mellem disse to pinde."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu er der i alt ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " m (", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " og ", _interpolate(_named("fourthNeedleStitchCount")), " m). Hvis du bruger rundpind, skal du sætte maskemarkører, hvor den 1. pind ville møde den 2. og hvor den 3. pind ville møde den 4."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik maskerne på 1. pind, indtil der er 2 m tilbage og strik 2 r sammen. Strik maskerne fra 2. og 3. pind. Tag 1 r løst af fra 4. pind, strik 1 m ret og træk den løse maske over den strikkede. Strik resten af maskerne."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag 2. og 3. pind ", _interpolate(_named("heelDecreaseCountMinusOne")), " gange mere, indtil du har i alt ", _interpolate(_named("footStitchCount")), " m."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sørg for, at maskerne er ligeligt fordelt på pindene, eller ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m, før du begynder at tage m ind ved tåen. Hvis du bruger rundpind, så sørg for, at maskemarkøren sidder, hvor den 1. pind ville møde den 2. pind, og hvor den 3. pind ville møde den 4. pind."])}
    }
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. omg:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. omg:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. og 3. omg:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. - 4. omg:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. omg:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. omg:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. omg:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. og 7. omg:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. omg:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. omg:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. pind:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. pind:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. pind:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. pind:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik glatstrik."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik en omg med indtagninger."])}
  },
  "mittens": {
    "Cuff_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkantens omkreds"])},
    "Cuff_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkantens omkreds målt om håndled (cm)"])},
    "Type_in_cuff_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ribkantens omkreds"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkantens længde"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkantens længde (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ribkantens længde"])},
    "Hand_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndens omkreds"])},
    "Hand_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndens omkreds målt ved håndryggen (uden tommelfinger) (cm)"])},
    "Type_in_hand_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast håndens omkreds"])},
    "Hand_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndens længde"])},
    "Hand_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndens længde målt fra håndled til fingerspids (cm)"])},
    "Type_in_hand_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast håndens længde"])},
    "Felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtning"])},
    "Felting_information_bubble_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanter som filtes, skal strikkes en del større end du ønsker at det endelige filtede resultat skal være. Vanterne filtes enten i hånden eller vaskemaskinen, indtil de er krympet til den ønskede størrelse."])},
    "Felting_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved denne behandling bliver vanterne tættere, og de holder derfor hænderne varmere og tørrere end ellers. Desuden vil vanterne holde længere."])},
    "Felting_density_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tæthed"])},
    "Choose_how_much_to_felt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg, hvor meget skal filtes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen filtning"])},
    "Felt_a_little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En smule (strikkes 10% større)"])},
    "Felt_somewhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mellem (strikkes 20% større)"])},
    "Felt_a_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meget (strikkes 30% større)"])},
    "Yarn_for_felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garn velegnet til filtning"])},
    "Yarn_for_felting_info_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uldgarn, som ikke er blevet superwash-behandlet, er mere velegnet til filtning. Forskellige garntyper filtes dog på forskellige måder. Desuden påvirker strikkefastheden filtningens resultat, løst strik filtes mere end stramt strik."])},
    "Yarn_for_felting_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgmulighederne \"En smule\", \"Mellem\" og \"Meget\" er derfor kun vejledende, da resultatet efter filtning er afhænge af den valgte garntype"])},
    "Suggestion_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forslag:"])},
    "Yarn_for_felting_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To tråde Plötulopi med strikkefasthed 16 m/10 cm."])},
    "Cuff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkant"])},
    "Cuff_felting_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribstrik krymper mere i længden end glatstrik, derfor er ribkantens længde blevet øget mere end opskriftens andre mål."])},
    "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("castOnStitchCount")), " m op på fire strømpepinde, strik rundt i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), " vr), indtil arbejdet måler ", _interpolate(_named("calculatedCuffLength")), " cm."])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hånd"])},
    "Knit_a_round_and_increase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik en omg, hvor du undervejs strikker ", _interpolate(_named("handCuffStitchCountDiff")), " udtagninger med jævne mellemrum fordelt over hele omg, så der er i alt ", _interpolate(_named("handStitchCount")), " m."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt i glatstrik, indtil arbejdet måler ", _interpolate(_named("beforeThumbLength")), " cm, målt fra ribkanten."])},
    "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at halvdelen af maskerne er på 1. og 2. pind og den anden halvdel på 3. og 4. pind."])},
    "Right_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højre vante:"])},
    "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik r over maskerne på 1. og 2. pind og ", _interpolate(_named("thumbMarginStitchCount")), " m på 3. pind. Strik de næste ", _interpolate(_named("thumbOpeningStitchCount")), " m med en hjælpetråd (restgarn). Sæt maskerne fra hjælpetråden over på venstre pind, strik disse masker og strik omg ud med originale garn."])},
    "Left_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venstre vante:"])},
    "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik r over maskerne på 1. pind. Strik r over maskerne på 2. pind, indtil der er ", _interpolate(_named("thumbMarginPlusThumbOpeningStitchCount")), " m tilbage på pinden. Strik ", _interpolate(_named("thumbOpeningStitchCount")), " m med en hjælpetråd. Sæt maskerne fra hjælpetråden over på venstre pind, strik disse masker og strik omg ud med oprindeligt garn."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagning"])},
    "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at halvdelen af maskerne er på 1. og 2. pind og den anden halvdel på 3. og 4. pind."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tåen formes med indtagninger således:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. omg (med indtagninger):"])},
    "Knit_one_stitch_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik 1 m r."])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Tag 1 m løst af, strik 1 m r og træk den løse m over den strikkede. Strik r omg ud."])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " Strik glatstrik, indtil der er ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " m tilbage på pinden. Strik 2 m r sammen", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", strik ", _interpolate(_named("tipMarginStitchCount")), " m ret"])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Strik som 1. omg."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Strik som 2. pind."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik indtagningsomganger, indtil der er ", _interpolate(_named("finalRowStitchCount")), " m tilbage. Bryd garnet og træk garn igennem maskerne."])},
    "Thumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tommelfinger"])},
    "Thumb_description_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fjern hjælpetråden, når tommelfingeren strikkes. Derved er der ", _interpolate(_named("thumbOpeningStitchCountMinusOne")), " m på øvre del af tommelfingeråbning og ", _interpolate(_named("thumbOpeningStitchCount")), " m på nedre del. Saml m op fra tommelfingeråbningen over på pinde. Saml 2 ekstra m op fra hver sin side af tommelfingeråbningen. Derved er der ", _interpolate(_named("initialThumbStitchCount")), " m på pindene, hvoraf ", _interpolate(_named("initialThumbStitchCountHalfFloor")), " m tilhører den øvre del af tommelfinger og ", _interpolate(_named("initialThumbStitchCountHalfCeil")), " m tilhører den nedre del."])},
    "Thumb_first_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begynd omg i den del af åbningen, som er tættest på håndfladen. Strik kantmaskerne drejet for at undgå huller."])},
    "Thumb_second_round_description_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær opmærksom på, at omg begynder i den øvre del af tommelfinger på højre vante og i den nedre del på den venstre vante."])},
    "Thumb_second_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den nederste del af tommelfinger strikkes således: Strik de første 2 m sammen. Strik r, indtil der er 2 m tilbage af den nedre del af tommelfinger, tag 1 m løst af, strik 1 m r og træk den løse m over den strikkede. Øverste del af tommelfinger: strik r over alle maskerne uden indtagninger. Efter denne omg er der i alt ", _interpolate(_named("thumbStitchCount")), " tommelfingermasker."])},
    "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik derefter rundt i glatstrik, indtil tommelfinger måler ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
    "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik indtagninger som beskrevet nedenfor, indtil der sammenlagt er ", _interpolate(_named("thumbFinalRowStitchCount")), " m tilbage på pindene."])},
    "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gør det samme for øvre og nedre del af tommelen: Tag 1 m løst af, strik 1 r og træk den strikkede m henover den strikkede. Strik i glatstrik, indtil der er 2 m tilbage. Strik de sidste 2 m r sammen."])},
    "Thumb_break_the_yarn_and_pull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bryd garnet og træk enden igennem maskerne."])},
    "Weave_in_all_ends_and_knit_the_other_mitten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæft løse ender. Strik den anden vante på samme måde men følg instruktionerne for \"Venstre vante\", hvis du startede med at strikke den højre."])},
    "Felting_description_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanterne kan enten filtes ved håndvask eller i vaskemaskine. Ved håndvask har du mest kontrol over, hvor meget vanterne krymper, da det kan variere en del mellem vaskemaskiner og forskellige vaskeprogrammer. Hvis vanterne krymper for meget, kan de strækkes til den rigtige størrelse, mens de stadig er fugtige. Hvis de stadig er for store, kan de vaskes igen på samme program og eventuelt ved højere temperatur."])},
    "Felting_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De filtede vanter vil normalt krympe mere, hvis de vaskes ved højere temperatur, hvis de vaskes med sæbe og hvis andre stykker tøj, vaskes sammen med vanterne. De andre stykker tøj må dog ikke afgive fnug."])},
    "Felting_a_little_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vask vanterne i vaskemaskine ved 40° på et kort program med centrifugering (ikke uldprogram). Brug en smule vaskepulver."])},
    "Felting_somewhat_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vask vanterne i vaskemaskine ved 40° på almindeligt program med centrifugering (ikke uldprogram). Brug en smule vaskepulver."])},
    "Felting_a_lot_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vask vanterne i vaskemaskine ved 60° på almindeligt program med centrifugering (ikke uldprogram). Brug en smule vaskepulver."])},
    "v1_1_0": {
      "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("castOnStitchCount")), " m op på fire strømpepinde (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m) eller på rundpind. Saml omg og strik rundt i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), " vr), indtil arb måler ", _interpolate(_named("calculatedCuffLength")), " cm."])},
      "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sørg for, at maskerne er lige fordelt eller ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m, før du fortsætter herfra. Hvis du bruger en rundpind, skal du sætte maskemarkør, hvor den 2. p ville møde den 3. p (efter ", _interpolate(_named("halfHandStitchCount")), " m)."])},
      "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("halfHandStitchCount")), " m på 1. og 2. p (eller til maskemarkør) og ", _interpolate(_named("thumbMarginStitchCount")), " m fra 3. p. Strik ", _interpolate(_named("thumbOpeningStitchCount")), " m med restgarn. Flyt restgarnmaskerne tilbage på venstre pind og strik dem sammen med bundgarnet. Strik resten af omg."])},
      "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("stitchCountUntilThumb")), " m. Strik ", _interpolate(_named("thumbOpeningStitchCount")), " m med restgarn. Flyt restgarnmaskerne tilbage på venstre pind og strik dem sammen med bundgarnet, samt de resterende ", _interpolate(_named("thumbMarginStitchCount")), " m på 2. p (eller indtil maskemarkøren). Strik resten af omg."])},
      "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Igen skal du sørge for, at maskerne er lige fordelt på pindene, eller ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " og ", _interpolate(_named("stitchCountEachEvenNeedle")), " m, før du fortsætter. Hvis du bruger en rundpind, skal du sørge for, at maskemarkøren er placeret, hvor den 2. p ville møde den 3. p (efter ", _interpolate(_named("halfHandStitchCount")), " m)."])},
      "Thumb_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start omg på den side af tommelfingeråbningen, der vender mod håndfladen, og strik ", _interpolate(_named("thumbInitialHalfStitchCount")), " m, mens du sørger for at strikke drejet, rettes de opsamlede masker fra siderne. Sæt maskemarkør og strik omg ud på same made."])},
      "Thumb_second_round_right_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " m, indtil du når maskemarkøren. Strik 2 r sammen. Strik ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " m, så der er 2 m tilbage på pinden, tag 1 r løst af, strik 1 r, og træk den løse m over den strikkede m."])},
      "Thumb_second_round_left_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik 2 r sammen. Strik ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " m (2 m skal være tilbage, inden du når maskemarkøren), tag 1 r løst af, strik 1 r, og træk den løse m over den strikkede m. Strik ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " m for at afslutte omg."])},
      "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der skal nu være ", _interpolate(_named("thumbStitchCount")), " på pindene. Strik rundt, indtil tommelfingeren måler ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
      "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu strikkes indtagningsomgange hveranden omg, indtil ", _interpolate(_named("thumbFinalRowStitchCount")), " m er tilbage."])},
      "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag 1 r løst af, strik 1 m og træk den løse m over den strikkede m, strik indtil 2 m før maskemarkør, strik 2 r sammen. Tag 1 m r løst af, strik 1 r, træk den løse m over den strikkede m, strik indtil 2 m er tilbage, strik 2 r sammen."])}
    }
  },
  "common3": {
    "Size_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Størrelse"])},
    "Choose_a_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg størrelse"])},
    "Print_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print opskrift"])},
    "This_browser_cannot_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er desværre ikke muligt at printe opskriften fra denne browser. Prøv venligst at åbne siden i en anden browser, fx i Google Chrome."])},
    "Decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagningsomgang:"])}
  },
  "beanie": {
    "This_pattern_assumes_a_stockinette_gauge_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskriften påregner, at strikkeprøven er strikket i glatstrik"])},
    "Head_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedomkreds"])},
    "Head_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hovedomkreds målt om panden og baghovedet, hvor det er bredest (cm)"])},
    "Type_in_head_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast hovedomkreds"])},
    "Brim_height_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantlængde"])},
    "Brim_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Længden af kanten som foldes op på huen (cm)"])},
    "Type_in_brim_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ombukskantens længde"])},
    "There_are_too_few_cast_on_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslagsmaskerne er for få. Vælg venligst enten en højere strikkefasthed eller vælg en større størrelse"])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hue"])},
    "Ribbing_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribdækning"])},
    "Whole_beanie_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hele huen"])},
    "Only_brim_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun ombukskanten"])},
    "Decrease_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagningsmåde"])},
    "Even_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usynlige indtagninger"])},
    "Cross_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krydsindtagning"])},
    "Body_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("castOnStitchCount")), " m op på en passende rundpind."])},
    "Body_cast_on_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slå gerne op med italiensk opslag (tubular cast on), men almindeligt krydsopslag (long tail cast on), eller et andet elastisk opslag fungerer også."])},
    "body_description_ribbed_start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt i rib, skiftevis ", _interpolate(_named("cuffPatternRepCountHalf")), " r og ", _interpolate(_named("cuffPatternRepCountHalf")), " vr, indtil arbejdet måler ", _interpolate(_named("straightCuffLength")), " cm."])},
    "body_description_stockinette_top_zero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm rundt i glatstrik"])},
    "body_description_stockinette_top_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm i glatstrik, indtil arbejdet måler ", _interpolate(_named("lengthBeforeDecrease")), " cm målt fra opslagskanten, eller ", _interpolate(_named("lengthBeforeDecreaseMinusFoldLength")), " cm hvis arbejdet måles, efter ombukskanten på ", _interpolate(_named("foldLength")), " cm er blevet foldet op. Det sidstnævnte mål er mere præcist, specielt hvis huen strikkes i tykt garn, da en del af huens længden bør forsvinde i ombukket."])},
    "body_description_whole_ribbed_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt i rib, ", _interpolate(_named("cuffPatternRepCountHalf")), " m r og ", _interpolate(_named("cuffPatternRepCountHalf")), " m vr, indtil arbejdet måler ", _interpolate(_named("straightCuffLength")), " cm målt fra opslagskanten, eller ", _interpolate(_named("straightCuffLengthMinusFoldLength")), " cm hvis arbejdet måles, efter ombukskanten på ", _interpolate(_named("foldLength")), " cm er blevet foldet op. Det sidstnævnte mål er mere præcist, specielt hvis huen strikkes i tykt garn, da en del af huens længden bør forsvinde i ombukket."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagning"])},
    "First_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. indtagningsomgang:"])},
    "First_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m r, 2 m vr sammen.**"])},
    "First_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tag 1 m løst af, strik 1 m og træk den løse m over den strikkede. Strik 1 r, 1 vr.**"])},
    "First_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m r, 2 m r sammen.**"])},
    "Repeat_from_star_to_2star_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag fra * til ** omg ud, så du ender med ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " m."])},
    "Knit_x_rounds_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("numberOfRowsBetweenDecreases")), " omg uden indtagninger."])},
    "Second_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. indtagningsomgang:"])},
    "Second_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m r sammen, 1 vr.**"])},
    "Second_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m ret sammen og så 1 m vr.**"])},
    "Second_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m r sammen, 1 r.**"])},
    "Third_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. indtagningsomg:"])},
    "Third_even_decrease_round_description_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Tag en m løst af, strik 1 m og træk den løse m over den strikkede m.**"])},
    "Third_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m ret sammen.**"])},
    "Final_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den sidste indtagningsomgang:"])},
    "Final_even_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Strik 2 m r sammen.**"])},
    "Repeat_from_star_to_2star_with_side_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag fra * til ** omg ud, så du ender med ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " m", _interpolate(_named("conditionalLastStitchSideNote")), "."])},
    "the_last_stitch_is_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (den sidste m strikkes alene)"])},
    "Break_the_yarn_and_pull_weave_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bryd garnet og træk enden igennem maskerne. Hæft ender."])},
    "Cross_decrease_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu strikkes indtagninger, hvor 2 m strikkes sammen 4 gange omg ud (8 m i alt). Marker eventuelt indtagningerne med maskemarkører."])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. omg (med indtagninger):"])},
    "Back_of_the_head_left_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baghovedet, venstre side:"])},
    "Work_x_stitches_in_x_stitch_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("typeOfStitch")), " over de næste ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " m."])},
    "stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["glatstrik"])},
    "rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rib"])},
    "Decrease_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagning:"])},
    "First_cross_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag 1 m løst af, strik 1 m r og træk den løse m over den strikkede. Strik derefter 2 m r sammen."])},
    "First_cross_decrease_round_description_rib": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik 2 m sammen ", _interpolate(_named("IntermediateRibStitches")), ". Tag 1 m løst af, strik 1 m og træk den løse m over den strikkede."])},
    "purl_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", strik 1 m vr"])},
    "work_x_stitches_in_rib_stitch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", strik ", _interpolate(_named("crossDecreaseColumnStitchesMinusTwo")), " m i rib"])},
    "Left_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venstre side:"])},
    "Work_x_stitches_in_x_stitch_and_then_decrease": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("typeOfStitch")), " over de næste ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " m og strik derefter indtagning."])},
    "Forehead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pande:"])},
    "Right_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højre side:"])},
    "Back_of_the_head_right_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baghovedet, højre side:"])},
    "Now_the_number_of_stitches_has_decreased_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu er der 8 færre m på pindene, så der i alt er ", _interpolate(_named("castOnStitchCountMinusEight")), " m."])},
    "Knit_the_round_in_x_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("typeOfStitch")), " uden indtagninger."])},
    "Knit_a_decrease_round_except_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strik omg med indtagninger, hvor indtagningerne placeres på linje med indtagningerne i den foregående omg (i nuværende omgang er maskerne mellem indtagningerne blevet reduceret med 2 masker)."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag de sidste to omg ", _interpolate(_named("everyOtherRowDecreaseCountMinusOne")), " gange, indtil der sammenlagt er ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " m tilbage på pindene."])},
    "Now_there_should_be_x_stitches_remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nu er der i alt ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " m tilbage på pindene."])},
    "Work_a_decrease_in_every_round_until_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lav nu indtagninger hver omg, indtil der sammenlagt er ", _interpolate(_named("crossDecreaseFinalRowStitchCount")), " m tilbage på pindene. Bryd garnet og træk enden igennem maskerne. Hæft enderne."])}
  },
  "common4": {
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])}
  },
  "navbar": {
    "Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrifter"])},
    "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værktøjer"])},
    "Sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på"])},
    "Sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ud"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonner"])}
  },
  "common_user_settings": {
    "First_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
    "Last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternavn"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny adgangskode"])},
    "Confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft adgangskode"])},
    "E_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailaddresse"])}
  },
  "sign_in": {
    "Not_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke en bruger?"])},
    "Sign_up_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld mig i stedet."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend"])},
    "Welcome_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen tilbage!"])},
    "Forgot_password_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glemt kode?"])},
    "Get_a_new_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil adgangskode."])}
  },
  "sign_up": {
    "Already_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du allerede en bruger?"])},
    "Sign_in_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på i stedet."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend"])},
    "Suceess_Your_account_has_been_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes! Din bruger er blevet oprettet."])},
    "Your_free_trial_starts_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din 14 dages gratis prøveperiode begynder nu."])},
    "We_ve_sent_a_verification_email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har sendt en bekræftelses-e-mail til din e-mailadresse. Tjek venligst din indbakke og klik på linket for at aktivere din bruger."])},
    "Need_help_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har brug for hjælp?"])},
    "Contact_our_support_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt vores supportteam."])},
    "Create_a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en bruger"])},
    "Knittable_can_send_me_marketing_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vil gerne modtage strikkeinspiration og være opdateret på nyeste strikkedesign og værktøjer"])},
    "I_accept_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer Knittables vilkår og betingelser"])}
  },
  "verify_email": {
    "Verify_my_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft e-mailadresse"])},
    "Your_email_address_has_been_verified_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din e-mailadresse er blevet bekræftet."])},
    "Sign_in_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på for at fortsætte."])},
    "Click_here_to_resend_verification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send bekræftelsesmailen igen"])},
    "Verification_email_has_been_resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelsesmailen er blevet sendt igen"])}
  },
  "user_profile": {
    "Reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil adgangskode"])},
    "Edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger profil"])},
    "Delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet bruger"])},
    "Are_you_sure_you_want_to_delete_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil slette din bruger?"])},
    "This_action_cannot_be_undone_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne handling kan ikke fortrydes."])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
    "User_Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruger profil"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil"])}
  },
  "common5": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend"])}
  },
  "get_started": {
    "Your_knitting_design_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din personlige strikkeassistent "])},
    "Unleash_your_creativity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slip din kreativitet løs og "])},
    "Unleash_your_creativity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strik "])},
    "Unleash_your_creativity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dine visioner"])},
    "Knittable_is_your_interactive_tool_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable er dit interaktive værktøj til at få det perfekte resultat med ethvert garn. Alt, du behøver, er en strikkefasthed og en ønsket størrelse, så skaber Knittable den perfekte opskrift."])},
    "Try_it_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv nu"])}
  },
  "how_does_it_work": {
    "How_does_it_work_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvordan virker det?"])},
    "1_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg garn"])},
    "2_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg størrelse"])},
    "3_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kom i gang"])},
    "1_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable passer til alle garnkvaliteter. Du skal bare indtast strikkefastheden."])},
    "2_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg mellem en række størrelser eller skræddersy din egen."])},
    "3_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grib dine pinde og begynd at strikke!"])},
    "1_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At vælge den rigtige strikkefasthed er afgørende for at opnå den ønskede pasform og drapering for dit færdige tøj."])},
    "2_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målene bruges til at beregne proportionerne af det strikkede tøj under hensyntagen til filtning og positiv eller negativ ease."])},
    "3_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mærk glæden ved den meditative rytme og kreativiteten ved at forvandle garn til et smukt kunstværk."])}
  },
  "pricing": {
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
    "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt"])},
    "yearly_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17% rabat"])},
    "paid_yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per måned- $55 betales årligt"])},
    "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift til denne plan "])},
    "Chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "Next_payment_due_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Næste betalingsdato ", _interpolate(_named("nextBillDate"))])},
    "Cancellation_effective_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opsigelsen træder i kraft efter ", _interpolate(_named("cancellationEffectiveDate"))])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opsig"])},
    "Payment_is_past_due_next_retry_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Betalingsdatoen er overskredet, betalingen forsøges gennemført igen ", _interpolate(_named("nextRetryDate"))])},
    "Subscription_expired_since": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abonnementet udløb ", _interpolate(_named("nextBillDate"))])},
    "Choose_what_fits_you_best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg det, der passer dig bedst"])},
    "Knitting_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkeværktøjer"])},
    "Pattern_add_on_for_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mønstertilføjelse til værktøjer "])},
    "Interactive_knitting_patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktive strikkeopskriftter"])}
  },
  "our_patterns": {
    "Our_Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vores opskrifter"])},
    "Our_patterns_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable giver dig interaktive strikkeopskrifter, der kan tilpasses din designidé"])},
    "Our_patterns_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrifterne passer til enhver garntype og enhver størrelse til både børn og voksne. Disse enkle opskrifter er ideelle til begyndere, der leder efter strik, der er nemt at gå til, eller erfarne strikkere, der ønsker en ramme til at skabe deres egne designs."])}
  },
  "subscription": {
    "Change_or_cancel_at_any_time_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan til enhver tid ændre eller opsige dine profilindstillinger"])},
    "Go_back_to_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])}
  },
  "gauge_from_swatch": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette værktøj beregner din strikkefasthed baseret på størrelsen på strikkeprøven og tæller masker og pinde i prøven."])},
    "Swatch_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkeprøvens dimensioner"])},
    "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Længde"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bredde"])},
    "Height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målt længde af strikkeprøven (cm)"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Målt bredde af strikkeprøven (cm)"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal masker i den målte bredde"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal pinde i den målte længde"])},
    "Height_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast længde"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast bredde"])},
    "Swatch_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål bredden/længden af din strikkeprøve, og tæl derefter masker/pinde i overensstemmelse hermed."])},
    "Swatch_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkeprøven behøver ikke at være 10 x 10 cm, men en større strikkeprøve vil give en mere nøjagtig måling."])},
    "Your_knitting_gauge_is_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strikkefastheden er ", _interpolate(_named("stitchGauge")), " m x ", _interpolate(_named("rowGauge")), " pinde = 10 x 10 cm."])}
  },
  "common6": {
    "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregn"])}
  },
  "welcome": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til Knittable"])},
    "You_have_x_left_of_your_free_trial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du har ", _interpolate(_named("remainingDays")), " dag(e) tilbage af din gratis prøveperiode"])},
    "View_subscription_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vores abonnementer"])}
  },
  "tracking_behavior": {
    "We_would_like_to_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi bruger cookies og lignende teknologier til at indsamle anonyme brugerdata, til analyseformål og for at øge brugertilfredsheden. Er det i orden?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])}
  },
  "pattern_form": {
    "A_new_version_is_available_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ny version af denne opskrift er tilgængelig. Afslut dit igangværende projekt og opgrader til den nye version til dit næste projekt."])},
    "Upgrade_and_calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opgrader og beregn opskrift"])}
  },
  "common7": {
    "Knit_calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkeberegner"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonner"])},
    "Subscribe_to_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonner for at aktivere"])},
    "Change_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregn strikkefasthed"])}
  },
  "knit_calculator": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette værktøj bruger din strikkefasthed til at beregne, hvor mange masker eller pinde du skal bruge for at strikke et stykke med ønsket bredde eller længde."])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masker"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pinde"])},
    "Stitch_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal masker per 10 cm"])},
    "Row_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal pinde per 10 cm"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bredde"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ønskede bredde"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønskede bredde(cm)"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Længde"])},
    "Length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ønskede længde"])},
    "Length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønskede længde (cm)"])},
    "Project_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit projekt"])},
    "Project_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkefastheden består af maskefasthed og pindefasthed. Maskefasthed bestemmer antallet af masker, der kræves for en 10 cm bredde, mens pindefasthed bestemmer antallet af pinde, der skal bruges til en 10 cm længde."])},
    "Project_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antallet af masker eller pinde beregnes ud fra din strikkefasthed, kombineret med den ønskede bredde eller længde, du har tænkt dig at strikke."])},
    "Pattern_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mønster"])},
    "Pattern_repetition_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug denne sektion, hvis du gerne vil tilføje et mønster til dit strik."])},
    "Pattern_repetition_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstra masker indgår i beregningerne men gentages ikke, f.eks. masker for at færdiggøre mønsteret eller kantmasker. Eksempel: Hvis hver side af arbejdet kræver 5 kantmasker, og der kræves 1 m for at færdiggøre mønsteret, så er antallet af ekstra m 5 + 5 + 1 = 11."])},
    "Pattern_repetition_information_bubble_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstra masker bruges generelt, når man strikker frem og tilbage, men er oftest 0, når man strikker rundt."])},
    "Pattern_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskeantal"])},
    "Pattern_stitch_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast maskeantal i mønstret"])},
    "Pattern_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antallet af masker, der skal bruges til hver gentagelse af mønstret "])},
    "Additional_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstra masker"])},
    "Additional_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast antal ekstra masker"])},
    "Additional_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet antal ekstra masker, der skal bruges for at afslutte mønstret"])},
    "Pattern_row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal pinde"])},
    "Pattern_row_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast pindeantal til mønster"])},
    "Pattern_row_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindeantal påkrævet til hver mønsterrapport"])},
    "Additional_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstra pinde"])},
    "Additional_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast antal ekstra pinde"])},
    "Additional_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet antal ekstra pinde, der skal bruges for at afslutte mønstret"])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " m svarer til ", _interpolate(_named("width")), " cm."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " pinde svarer til ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " masker svarer til ", _interpolate(_named("width")), " cm (", _interpolate(_named("widthDifference")), " cm). Det vil sige ", _interpolate(_named("patternRepetition")), " mønstergentagelse og ", _interpolate(_named("patternAdditionalCount")), " ekstra maske(r)."])},
    "Rows_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " pinde svarer til ", _interpolate(_named("length")), " cm (", _interpolate(_named("lengthDifference")), " cm). Det vil sige ", _interpolate(_named("patternRepetition")), " mønstergentagelse og ", _interpolate(_named("patternAdditionalCount")), " ekstra pinde."])}
  },
  "gauge_converter": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette værktøj konverterer maske- eller pindeantal for at opnå en ensartet bredde eller længde ved hjælp af en ny strikkefasthed. Det kan være nyttigt, når man skifter garn i et mønster."])},
    "Current_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette afsnit gælder for din aktuelle strikkefasthed og den du skifter til og planlægger at bruge til dit projekt."])},
    "Original_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette afsnit gælder for den originale strikkefasthed, den du skifter fra. Dette kan f.eks. være en strikkefasthed til en opskrift, og de tilhørende antal masker eller pinde."])},
    "Current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit projekt"])},
    "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønskede antal masker beregnes"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønskede antal pinde beregnes"])},
    "Stitch_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle antal masker på 10 cm"])},
    "Row_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle antal pinde på 10 cm"])},
    "Stitch_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originale antal masker på 10 cm"])},
    "Row_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Originale antal pinde på 10 cm"])},
    "Original_stitch_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ønskede bredde er ", _interpolate(_named("width")), " cm"])},
    "Original_row_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ønskede længde er ", _interpolate(_named("length")), " cm"])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette svarer til ", _interpolate(_named("stitchCount")), " m med den nuværende maskefasthed."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette svarer til ", _interpolate(_named("rowCount")), " pinde med din nuværende pindefasthed."])}
  },
  "common_tools": {
    "Calculate_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beregn"])},
    "Stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskefasthed"])},
    "Stitch_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast maskeantal på 10 cm"])},
    "Row_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindefasthed"])},
    "Row_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast pindeantal på 10 cm"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maskeantal"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pindeantal"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast maskeantal"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast pindeantal"])}
  },
  "forgot_password": {
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny Adgangskode"])},
    "A_new_password_has_successfully_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hurra! Klik på linket i e-mailen for at nulstille adgagnskode."])},
    "Password_successfully_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din adgangskode er blevet nulstillet."])}
  },
  "sweater": {
    "chest_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brystvidde"])},
    "Fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasform"])},
    "Fitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilpasset"])},
    "Tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Skræddersyet "])},
    "Relaxed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rummelig"])},
    "Loose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Løs"])},
    "Oversized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversized"])},
    "Choose_fit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg størrelse"])},
    "Chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brystvidde"])},
    "Chest_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroppens omkreds (ikke sweateren) over brystet, hvor det bredeste sted er (cm)"])},
    "Type_in_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast brystvidde"])},
    "Body_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevægelsesrum"])},
    "Body_ease_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføjet bevægelsesrum til overvidden, for at opnå den strikkede brystovervidde (cm)"])},
    "Type_in_body_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast kroppens bevægelsesrum"])},
    "Finished_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkede brystovervidde"])},
    "Finished_chest_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweaterens overvidde (ikke kroppen) over brystet, hvor det bredeste sted er (cm)"])},
    "Type_in_finished_chest_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast strikkede brystovervidde"])},
    "Sweater_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweaterens længde"])},
    "Sweater_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweaterens længde målt bagpå med halskanten og ribkanten (cm)"])},
    "Type_in_sweater_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast sweaterens længde"])},
    "Hem_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kantlængde"])},
    "Hem_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribkantens længde i bunden af kroppen (cm)"])},
    "Type_in_hem_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast kantens længde"])},
    "Cuff_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchetomkreds"])},
    "Cuff_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchetomkreds til ærmerne (cm)"])},
    "Type_in_cuff_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ærmeomkreds"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchet længde"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manchetlængde til ærmerne (cm)"])},
    "Wrist_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndledsomkreds"])},
    "Wrist_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omkreds af håndleddet (cm)"])},
    "Type_in_wrist_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indast håndledsomkreds"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast manchetlængde"])},
    "Neckband_circumf_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskantomkreds"])},
    "Neckband_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskantomkreds (cm)"])},
    "Type_in_neckband_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast halskantomkreds"])},
    "Neckband_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskantlængde"])},
    "Neckband_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskantlængde (cm)"])},
    "Type_in_neckband_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast halskantlængde"])},
    "Arm_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmeomkreds"])},
    "Arm_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Omkreds af øverste del af ærmet, hvor den er bredest (cm)"])},
    "Type_in_arm_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ærmeomkreds"])},
    "Sleeve_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmets bevægelsesrum"])},
    "Sleeve_ease_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevægelsesrum tilføjet til ærmeomkreds, for at opnå ærmeomkreds (cm)"])},
    "Type_in_sleeve_ease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ærmets bevægelsesrum"])},
    "Sleeve_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmeomkreds"])},
    "Sleeve_circumf_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omkreds i toppen af ærmet (cm)"])},
    "Type_in_sleeve_circumf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ærmeomkreds"])},
    "Arm_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armlængde"])},
    "Arm_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armens længde (ikke ærmet) fra armhule til håndled med armen naturligt hængende ved siden af ​​kroppen (cm)"])},
    "Type_in_arm_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast armlængde"])},
    "Sleeve_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmelængde"])},
    "Sleeve_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmelængde med manchetten (cm)"])},
    "Type_in_sleeve_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast ærmelængde"])},
    "Raglan_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raglan maskeantal"])},
    "Raglan_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal masker per raglan"])},
    "Type_in_raglan_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast raglan maskeantal"])},
    "Body_pattern_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroppens mønstergentagelse"])},
    "Body_pattern_repeat_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal masker per mønsterrapport til kroppen"])},
    "Type_in_body_pattern_repeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast kroppens mønsterrapport"])},
    "Neckband": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halskant"])},
    "Neckband_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slå ", _interpolate(_named("neckbandStitchCount")), " m op på rundpind, som er en størrelse mindre, for at opnå  din indtastede strikkefasthed."])},
    "Neckband_knit_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Indsæt maskemarkør (mm) for omgangens begyndelse, og strik rundt i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), " vr) indtil arbejdet måler ", _interpolate(_named("doubleNeckbandLength")), " cm i længde."])},
    "Neckband_fold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ombuk halskanten og strik den fast således:"])},
    "Neckband_fold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Saml 1 m fra opsamlingskanten op på venstre pind og strik den nye m r sammen med første m på pinden. *Saml den næste m fra opsamlingskanten op på venstre pind og strik 2 r sammen.** Gentag fra * til ** omg ud."])},
    "Yoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bærestykke"])},
    "Yoke_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bærestykket strikkes rundt, med 4 raglan m med udtagninger på begge sider på hver 2. omg. Raglanmaskerne kan strikkes i glatstrik eller i fortsat rib. Omgangens begyndelse er midt bagpå."])},
    "Yoke_first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift til pindstørrelsen, der giver den indtastede strikkefasthed, og strik 1 omg samtidig indsæt 8 raglan maskemarkører således:"])},
    "Yoke_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("raglanFirstSectionFirstHalf")), " m, indsæt 1. maskemarkør, strik ", _interpolate(_named("raglanStitchCount")), " raglan m, indsæt 2. maskemarkør, strik ", _interpolate(_named("raglanSecondSection")), " m, indsæt 3. maskemarkør, strik ", _interpolate(_named("raglanStitchCount")), " raglan m, indsæt 4. maskemarkør, strik ", _interpolate(_named("raglanThirdSection")), " m, indsæt 5. maskemarkør, strik ", _interpolate(_named("raglanStitchCount")), " raglan m, indsæt 6. maskemarkør, strik ", _interpolate(_named("raglanFourthSection")), " m, indsæt 7. maskemarkør, strik ", _interpolate(_named("raglanStitchCount")), " raglan m, indsæt 8. maskemarkør og strik ", _interpolate(_named("raglanFirstSectionSecondHalf")), " til p afslutning."])},
    "Work_raglan_increases_as_follows_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu strikkes raglan udtagninger:"])},
    "Raglan_increase_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Strik til den næste maskemarkør, udt-h, strik ", _interpolate(_named("raglanStitchCount")), " raglan m til den næste maskemarkør, udt-v. Gentag fra * til ** yderligere 3 gange. Strik p ud."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gentag 1. og 2. omg yderligere ", _interpolate(_named("raglanIncreasesMinusOne")), " gange (", _interpolate(_named("raglanIncreases")), " i alt), indtil der er ", _interpolate(_named("maxYokeStitchCount")), " m på pinden."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krop"])},
    "Divide_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Del maskerne i ærmer og krop, samtidig slås m op til armhulerne således:"])},
    "Divide_the_stitches_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("rightBackStitchCount")), " m, sæt de næste ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " m op en maskeholder og slå ", _interpolate(_named("underarmStitchCount")), " m op til ærmegabet med løkkeopslag. Strik ", _interpolate(_named("frontStitchCount")), ", sæt de næste ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " m på en maskeholder, slå ", _interpolate(_named("underarmStitchCount")), " m op til ærmegabet og strik ", _interpolate(_named("leftBackStitchCount")), " m til p afslutning."])},
    "Knit_additional_x_to_new_beginning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik ", _interpolate(_named("stitchCountToNewBeginning")), " flere m og sæt en maskemarkør for omgangens begyndelse."])},
    "There_should_now_be_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der er nu ", _interpolate(_named("bodyStitchCount")), " m på pinden."])},
    "In_development_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne opskrift er i øjeblikket under udvikling og kan blive ændret pludseligt. Brug den venligst med forsigtighed."])},
    "Cropped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cropped"])},
    "Regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
    "Long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Længde"])},
    "Choose_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg længde"])},
    "Knit_in_the_round_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt, indtil kroppen måler ", _interpolate(_named("sweaterLengthMinusHem")), " cm målt midt bagpå, halskanten medregnet."])},
    "Change_needle_and_knit_the_hem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skift til en pindstørrelse mindre og strik ", _interpolate(_named("hemLength")), " cm i rib (", _interpolate(_named("cuffPatternRepCountHalf")), " r, ", _interpolate(_named("cuffPatternRepCountHalf")), "vr)."])},
    "Finished_measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strikkede mål"])},
    "Measurement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mål"])},
    "Requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ønsket"])},
    "Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultat"])},
    "Difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forskel"])},
    "Size_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brystovervidde (dig selv)"])},
    "Bind_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Luk af med din foretrukne aflukningsteknik, f.x. almindelig aflukning eller italiensk aflukning."])},
    "Sleeves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmerne"])},
    "The_sleeves_are_worked_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ærmerne strikkes rundt på strømpepinde eller lange rundpinde med Magic Loop teknik."])},
    "Transfer_stitches_to_needles": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Flyt ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " m fra maskeholderen til pinden."])},
    "Sleeves_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start midt i de opslåede m til ærmegabet og strik ", _interpolate(_named("underarmCeilHalfStitchCount")), " m op. Strik ", _interpolate(_named("sleeveStitchCountMinusUnderarm")), " m, strik de resterende ", _interpolate(_named("underarmFloorHalfStitchCount")), " m fra ærmegabet. Der er nu ", _interpolate(_named("sleeveStitchCount")), " m på pinden."])},
    "Sleeves_continue_knitting_in_the_round": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Indsæt maskemarkør for omgangens begyndelse og strik rundt, indtil ærmet måler ", _interpolate(_named("sleeveAboveDecreasesLength")), " cm fra ærmegabet."])},
    "Work_the_decreases_as_follows_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtagninger strikkes nu således:"])},
    "Knit_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1r."])},
    "Sleeve_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 m r sammen, strik, indtil der er 2 m tilbage på pinden. Tag 1 m ret løst af, 1 r, træk den løse m over den strikkede m."])},
    "There_are_now_x_stitches_on_your_needles_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der er nu ", _interpolate(_named("stitchCount")), " m på pinden."])},
    "Continue_working_the_sleeve_performing_decreases_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ærmet fortsættes rundt med en indtagningsomgang hver ", _interpolate(_named("betweenDecreasesRoundCountPlusOne")), " omg yderligere ", _interpolate(_named("decreaseRoundCountMinusOne")), " gange (", _interpolate(_named("decreaseRoundCount")), " i alt)."])},
    "Knit_in_the_round_until_the_sleeve": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik rundt, indtil ærmet måler ", _interpolate(_named("sleeveLengthMinusCuffLength")), " cm fra ærmegabet."])},
    "Knit_the_last_two_stitches_togeter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Strik de sidste 2 m fra sidste omg sammen, for at opnå ", _interpolate(_named("cuffStitchCount")), " m."])},
    "Knit_the_two_stitches_togeter_x_times": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tag ud, indtil der er ", _interpolate(_named("cuffStitchCount")), " m ved at strikke 2 r sammen ", _interpolate(_named("additionalDecreaseCount")), " gange, jævnt omg rundt."])},
    "Change_to_a_needle_size_smaller_to_knit_the_cuff": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skift til en pindstørrelse mindre og strik manchetten i (ret ", _interpolate(_named("cuffPatternRepCountHalf")), " vrang ", _interpolate(_named("cuffPatternRepCountHalf")), "), indtil manchetten måler ", _interpolate(_named("cufflength")), " cm i længden."])},
    "Finishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afslutning"])},
    "Weave_in_and_block_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hæft alle ender og blok sweateren."])},
    "Pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opskrift"])}
  }
}