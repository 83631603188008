<template>
  <v-app id="app">
    <navbar />
    <v-main
      :class="['app-main-container', { 'app-md-and-up': mdAndUp, 'app-sm-and-down': !mdAndUp }]"
    >
      <router-view />
    </v-main>
    <app-footer />
    <behavior-tracking-dialog></behavior-tracking-dialog>
  </v-app>
</template>

<script setup>
import { onMounted } from "vue";
import mp from "./userTracking/mixpanel";

import BehaviorTrackingDialog from "./components/BehaviorTrackingDialog.vue";
import { useAuthStore } from "./stores/auth";
import Navbar from "./components/Navbar.vue";
import AppFooter from "./components/AppFooter.vue";
import { useDisplay } from "vuetify";

const authStore = useAuthStore();

const { mdAndUp } = useDisplay();

const loadUserAndIdentify = async () => {
  if (authStore.authToken) {
    try {
      const user = await authStore.getUser();
      if (user) {
        mp.identify(user.id);
      }
    } catch (error) {
      console.log("Error identifying user for tracking");
    }
  }
};

onMounted(() => {
  loadUserAndIdentify();
});
</script>

<style lang="scss">
@import "../scss/styles.scss";

:root {
  --knittable-lav: #9872d7;
  --knittable-lav-medium: #b99ee6;
  --knittable-lav-light: #e0d5eb;
  --knittable-lav-lighter: #ede6f3;
  --knittable-lav-darker: #8160b7;
  --knittable-yama-light: #edd48f;
  --knittable-yama: #edae05;
  --knittable-yama-darker: #d09e02;
  --knittable-mocha: #9e7f71;
  --knittable-macchiato: #755442;
  --knittable-isa: #f8f6f2;
  --knittable-ama: #e4404f;
  --knittable-opal: #96b9ae;
  --knittable-opal-dark: #7ea498;
  --knittable-begonia: #f36f7a;
  --knittable-begonia-medium: #fa9ea6;
  --knittable-begonia-light: #fed1d5;
  --knittable-black: #292929;
  --knittable-grey: #cac8c5;
  --knittable-grey-dark: #9f9d9a;
  --knittable-verdigris: #53b8b2;
  --knittable-latte: #c7a9a0;
  --knittable-jade: #3ca56a;
  --knittable-sapgreen: #497645;
  --knittable-dustymiller: #8ca3be;
}

h1 {
  font-family: Philosopher;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 5.5rem */
}

.app-sm-and-down h1 {
  font-family: Philosopher;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 3.3rem */
}

h2 {
  font-family: Philosopher;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 4.95rem */
}

.app-sm-and-down h2 {
  font-family: Philosopher;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 2.75rem */
}

h3 {
  font-family: Philosopher;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 4.4rem */
}

.app-sm-and-down h3 {
  font-family: Philosopher;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 2.475rem */
}

h4 {
  font-family: Philosopher;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 3.85rem */
}

.app-sm-and-down h4 {
  font-family: Philosopher;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 2.2rem */
}

h5 {
  font-family: Philosopher;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 3.3rem */
}

.app-sm-and-down h5 {
  font-family: Philosopher;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.925rem */
}

h6 {
  @include heading6;
}

.app-sm-and-down h6 {
  font-family: Philosopher;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.65rem */
}

.app-subtitle-small {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.5rem */
}

.app-page-title {
  margin: 3.5rem 2rem 1.5rem;

  color: var(--knittable-lav-darker);
}

.app-page-subtitle {
  @include subtitleSmall;

  margin: 0 1.5rem 3rem;
  color: var(--knittable-mocha);
}

//----------
.app-error {
  color: var(--knittable-ama);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-grayed-out {
  opacity: 0.4; /* Real browsers */
  filter: alpha(opacity = 40); /* MSIE */
}

.form-group--error input {
  box-shadow: 0 0 5px var(--error-color);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid var(--error-color);
}

.form-group--error .error {
  color: var(--error-color);
}

a.v-btn.app-button,
button.v-btn.app-button {
  border-radius: 1rem;

  padding: 1rem 2rem;
  gap: 1.5rem;
  height: 4rem;

  .v-btn__content {
    font-family: Philosopher;
  }
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  text-transform: none;
  letter-spacing: 0.0225rem;
}

.app-button:disabled {
  opacity: 0.5;
}

.app-heading {
  color: var(--main-color);
}

.app-tabs a.nav-link.disabled {
  color: white;
}

.app-tabs a.nav-link.disabled {
  background-color: var(--main-color);
  border-bottom-color: transparent;
}

.app-display-none {
  display: none;
}

.app-hidden {
  visibility: hidden;
}

.app-page-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.app-img-container img {
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 100%;
  max-height: 510px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.app-recipe-form-invalid-message {
  margin: 0 auto;
  display: block;
  text-align: center;
  position: relative;
  background-color: var(--main-color);
  color: white;
  z-index: 1000;
  padding: 15px;
  border-radius: 5px;
}

.app-recipe-form-invalid-message-location {
  margin: 0;
  position: absolute;
  top: 150px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.app-central-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 4rem;
}

.app-central-card {
  width: 60%;
}

.app-sm-and-down .app-central-card {
  width: 90%;
}

.app-loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mdi-spin {
  animation: mdi-spin 2s infinite linear;
  font-size: 2em;
  color: white;
}

@keyframes mdi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-main.app-main-container {
  padding-top: 110px;
  background-color: var(--knittable-isa);
}

button.app-btn-icon {
  min-width: auto;
  font-size: 1.375rem;
}
</style>
